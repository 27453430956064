import React from 'react';
import { useSelector } from 'react-redux';

function GlobalLoading() {
  const showLoading = useSelector(state => state.ui.showLoading);

  return (
    showLoading === true && (
      <div className="loading-box">
        <div className="spinner">
          <div className="spinner__item1" />
          <div className="spinner__item2" />
          <div className="spinner__item3" />
          <div className="spinner__item4" />
        </div>
      </div>
    )
  );
}

export default GlobalLoading;

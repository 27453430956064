import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as uiActions from '@Store/actions/ui';

export default function ItemSidebar({ item }) {
  const hotel = useSelector(state => state.auth.hotel);
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (`${routeMatch.path}` === `/:slug${item.path}`) {
      dispatch(uiActions.setSidebarActive(item.id));
      dispatch(uiActions.clearChildSidebarAcitive());
    }
  }, []);

  const handleClickItem = () => {
    dispatch(uiActions.setSidebarActive(item.id));
    history.push(`/${hotel.slug}${item.path}`);
  };

  return (
    <li className={`${item.isActive ? 'active-item' : ''}`}>
      <button className="btn nav-link w-100" type="button" onClick={() => handleClickItem()}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <img src={item.icon} className="mg-r-10" alt="icon" />
            <span className="tx-16 tx-medium sidebar-menu-name">{item.name}</span>
          </div>
          {item.child && <Icon.ChevronRight />}
        </div>
      </button>
    </li>
  );
}

memo(ItemSidebar);

ItemSidebar.propTypes = {
  item: PropTypes.object,
};

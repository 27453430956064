import * as types from '../actionTypes/ui';

const initialState = {
  showLoading: false,
  menu: '',
  isExpandMenu: true,
  sidebar: [],
  chidActive: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        showLoading: true,
      };
    case types.HIDE_LOADING:
      return {
        ...state,
        showLoading: false,
      };
    case types.MINI_MENU:
      return {
        ...state,
        menu: 'minimize',
      };
    case types.NORMAL_MENU:
      return {
        ...state,
        menu: '',
      };
    case types.TOGGLE_MENU: {
      const { isExpandMenu } = state;
      return {
        ...state,
        isExpandMenu: !isExpandMenu,
      };
    }
    case types.SET_SIDEBAR: {
      const { data } = action.payload;
      return {
        ...state,
        sidebar: data,
      };
    }
    case types.SET_SIDEBAR_ACTIVE: {
      const { sidebarId } = action.payload;
      const { sidebar } = state;
      const setActiveSidebar = sidebar.map(item => {
        if (item.id === sidebarId) {
          item.isActive = true;
          item.isOpen = true;
        } else {
          item.isActive = false;
          item.isOpen = false;
        }
        return item;
      });
      return {
        ...state,
        sidebar: [...setActiveSidebar],
      };
    }
    case types.TOGGLE_CHILD_SIDEBAR: {
      const { sidebar } = state;
      const findActiveSidebar = sidebar.find(item => item.isActive);
      findActiveSidebar.isOpen = !findActiveSidebar.isOpen;
      return {
        ...state,
        sidebar: [...sidebar],
      };
    }
    case types.SET_CHILD_SIDEBAR_ACTIVE: {
      const { childSidebar } = action.payload;
      return {
        ...state,
        chidActive: childSidebar,
      };
    }
    case types.CLEAR_CHILD_SIDEBAR_ACTIVE: {
      return {
        ...state,
        chidActive: null,
      };
    }
    case types.CLEAR_SIDEBAR: {
      return {
        ...state,
        sidebar: [],
      };
    }
    default:
      return state;
  }
};

export default reducer;

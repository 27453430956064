import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as uiActions from '@Store/actions/ui';
import { DASHBOARD_TYPE } from '../../../../constants';

export default function NavMenu({ type }) {
  const hotel = useSelector(state => state.auth.hotel);
  const history = useHistory();
  const dispatch = useDispatch();
  const [urlHotelRooms, setUrlHotelRoom] = useState('');

  const handleChangeDashboard = url => {
    history.push(url);
    dispatch(uiActions.clearSidebar());
  };

  useEffect(() => {
    if (hotel) {
      const isAdmin = hotel.userRole.is_admin;
      const url =
        isAdmin === 0 ? `/${hotel.slug}/order-history/vouchers` : `/${hotel.slug}/dashboard`;
      setUrlHotelRoom(url);
    }
  }, [hotel]);
  return (
    <div className="navbar-menu-wrapper">
      <ul className="nav navbar-menu">
        <>
          <li className={`nav-item ${type === DASHBOARD_TYPE.HOTEL_ROOMS ? 'active' : ''}`} key={1}>
            <button
              className="btn nav-link h-100"
              type="button"
              onClick={() => handleChangeDashboard(urlHotelRooms)}
            >
              HOTEL & ROOMS
            </button>
          </li>
          <li
            className={`nav-item ${type === DASHBOARD_TYPE.IN_STAY_SERVICE ? 'active' : ''}`}
            key={2}
          >
            <button
              className="btn nav-link h-100"
              type="button"
              onClick={() => handleChangeDashboard(`/${hotel.slug}/in-stay-service`)}
            >
              IN-STAY SERVICE
            </button>
          </li>
          <li className={`nav-item ${type === 'chat' && 'active'}`} key={3}>
            <button
              className="btn nav-link h-100"
              type="button"
              onClick={() => handleChangeDashboard(`/${hotel.slug}/chat`)}
            >
              LIVE CHAT
            </button>
          </li>
        </>
      </ul>
    </div>
  );
}

NavMenu.propTypes = {
  type: PropTypes.string,
};

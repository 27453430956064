import React from 'react';

function Loader() {
  return (
    <div className="loading-box">
      <div className="spinner">
        <div className="spinner__item1" />
        <div className="spinner__item2" />
        <div className="spinner__item3" />
        <div className="spinner__item4" />
      </div>
    </div>
  );
}

export default Loader;

export const FETCH_LABELS = 'FETCH_LABELS';
export const FETCH_LABELS_SUCCESS = 'FETCH_LABELS_SUCCESS';
export const FETCH_LABELS_FALED = 'FETCH_LABELS_FALED';

export const ADD_LABEL = 'ADD_LABEL';
export const ADD_LABEL_SUCCESS = 'ADD_LABEL_SUCCESS';
export const ADD_LABEL_FAILED = 'ADD_LABEL_FAILED';

export const DELETE_LABEL = 'DELETE_LABEL';
export const DELETE_LABEL_SUCCESS = 'DELETE_LABEL_SUCCESS';
export const DELETE_LABEL_FAILED = 'DELETE_LABEL_FAILED ';

export const ADD_LABEL_CUSTOMER = 'ADD_LABEL_CUSTOMER';
export const ADD_LABEL_CUSTOMER_SUCCESS = 'ADD_LABEL_CUSTOMER_SUCCESS';
export const ADD_LABEL_CUSTOMER_FAILED = 'ADD_LABEL_CUSTOMER_FAILED';

export const DELETE_LABEL_CUSTOMER = 'DELETE_LABEL_CUSTOMER';
export const DELETE_LABEL_CUSTOMER_SUCCESS = 'DELETE_LABEL_CUSTOMER_SUCCESS';
export const DELETE_LABEL_CUSTOMER_FAILED = 'DELETE_LABEL_CUSTOMER_FAILED';

export const ADD_NOTE = 'ADD_NOTE';
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';
export const ADD_NOTE_FAILED = 'ADD_NOTE_FAILED';

export const FETCH_NOTES = 'FETCH_NOTES';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const FETCH_NOTES_FAILED = 'FETCH_NOTES_FAILED';

export const EDIT_NOTE = 'EDIT_NOTE';
export const EDIT_NOTE_SUCCESS = 'EDIT_NOTE_SUCCESS';
export const EDIT_NOTE_FAILED = 'EDIT_NOTE_FAILED';

export const DELETE_NOTE = 'DELETE_NOTE';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAILED = 'DELETE_NOTE_FAILED';

export const CLEAR_DATA_LABEL_CUSTOMER = 'CLEAR_DATA_LABEL_CUSTOMER';

import { googleTranslate } from '../apis/chat';

export const getIdsFromHotels = (hotels, hotelActive) => {
  const ids = [];
  hotels.forEach(hotel => {
    // if (hotelActive.fb_page_id) {
    ids.push(hotel.id);
    // }
  });
  return ids;
};

export const mapCustomerToConversation = (conversations, customers) => {
  conversations.data.map(item => {
    const find = customers.find(customer => customer.channel_id === item.user.channel_id);
    item.customer = find;
  });
  return conversations;
};

export const isMemberActive = (memberActive, idCheck) => {
  return memberActive && memberActive.id === idCheck;
};

export const checkNotificationExitHotels = (notificationHotelId, hotels) => {
  const check = hotels.find(hotel => hotel.id === notificationHotelId);
  if (check) return true;
  return false;
};

export const translateTextMessenger = ({ messenges, translateTo }) => {
  const messengesLength = messenges.length;
  const requests = [];
  if (messengesLength > 60) {
    for (let i = 0; i < messenges.length; i += 60) {
      const messenge = messenges.slice(i, i + 60);
      const resp = translateMessenge(messenge, translateTo);
      requests.push(resp);
    }
    return Promise.all(requests);
  }
  return translateMessenge(messenges, translateTo);
};

async function translateMessenge(messenge, translateTo) {
  const cloneRaw = JSON.parse(JSON.stringify(messenge));
  const rawText = cloneRaw.map(mess => mess.message_raw.text || '');
  const dataTrans = makeTrans(rawText, translateTo);
  const resp = await googleTranslate(dataTrans);
  return resp;
}

function makeTrans(q, target) {
  return {
    q,
    target,
    format: 'text',
  };
}

export const changeMessageRawTranslate = (trans, messengesShow) => {
  let translateData = [];
  if (trans.length > 0) {
    translateData = trans
      .map(tran => tran.data.data.translations)
      .reduce((acc, val) => [...acc, ...val]);
  } else {
    translateData = trans.data.data.translations;
  }
  const textTranslate = translateData.map(tran => tran.translatedText);
  const translations = messengesShow.map((mess, index) => {
    mess.message_raw.text = textTranslate[index];
    return mess;
  });
  return translations;
};

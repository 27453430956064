import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import chat from './chat';
import labels from './labels';
import ui from './ui';
import webview from './webview';
import chatv2 from './chatv2';
import instayOrder from './instayOrder';

const rootReducer = history => {
  return combineReducers({
    auth,
    chat,
    labels,
    ui,
    webview,
    chatv2,
    instayOrder,
    router: connectRouter(history),
  });
};

export default rootReducer;

import React, { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useSelector, useDispatch } from 'react-redux';
import * as uiActions from '@Store/actions/ui';
import PropTypes from 'prop-types';
import { dashboardConstant, inStayServiceConstant } from '@Components/Routes/RouterConstant';
import { DASHBOARD_TYPE } from '../../../../constants';
import ItemSidebar from './ItemSidebar';
import ItemSidebarHasSub from './ItemSidebarHasSub';

export default function SideBar({ type }) {
  const hotel = useSelector(state => state.auth.hotel);
  const isExpandMenu = useSelector(state => state.ui.isExpandMenu);
  const sidebar = useSelector(state => state.ui.sidebar);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sidebar.length === 0) {
      if (type === DASHBOARD_TYPE.HOTEL_ROOMS) {
        let routesConstant = dashboardConstant;
        const isAdmin = hotel.userRole.is_admin;
        if (hotel && isAdmin === 0) {
          routesConstant = dashboardConstant.filter(item => item.isStaff);
        }
        dispatch(uiActions.setSidebar(routesConstant));
      } else if (type === DASHBOARD_TYPE.IN_STAY_SERVICE) {
        dispatch(uiActions.setSidebar(inStayServiceConstant));
      }
    }
  }, [sidebar, hotel]);

  const toggleMenu = () => {
    dispatch(uiActions.toggleMenu(!isExpandMenu));
  };

  return (
    <div className="sidebar-menu-fixed h-100 pd-t-30">
      <button onClick={() => toggleMenu()} type="button" className="btn btn-toggle-menu">
        {isExpandMenu ? (
          <>
            <Icon.ChevronLeft />
            <span className="mg-l-5 tx-13 tx-medium">MENU</span>
          </>
        ) : (
          <Icon.ChevronRight />
        )}
      </button>
      <PerfectScrollbar className="h-100">
        <ul className="sidebar-menu h-100">
          {sidebar.length > 0 &&
            sidebar.map(route => {
              if (route.child) {
                return <ItemSidebarHasSub item={route} key={route.id} />;
              }
              return <ItemSidebar item={route} key={route.id} />;
            })}
        </ul>
      </PerfectScrollbar>
    </div>
  );
}

SideBar.propTypes = {
  type: PropTypes.string,
};

import * as types from '../actionTypes/ui';

export const showLoading = () => ({
  type: types.SHOW_LOADING,
});

export const hideLoading = () => ({
  type: types.HIDE_LOADING,
});

export const miniMenu = () => ({
  type: types.MINI_MENU,
});

export const normalMenu = () => ({
  type: types.NORMAL_MENU,
});

export const toggleMenu = () => ({
  type: types.TOGGLE_MENU,
});

export const setSidebar = data => ({
  type: types.SET_SIDEBAR,
  payload: {
    data,
  },
});

export const setSidebarActive = sidebarId => ({
  type: types.SET_SIDEBAR_ACTIVE,
  payload: {
    sidebarId,
  },
});

export const toggleChildSidebar = () => ({
  type: types.TOGGLE_CHILD_SIDEBAR,
});

export const setChildSidebarActive = childSidebar => ({
  type: types.SET_CHILD_SIDEBAR_ACTIVE,
  payload: {
    childSidebar,
  },
});

export const clearChildSidebarAcitive = () => ({
  type: types.CLEAR_CHILD_SIDEBAR_ACTIVE,
});

export const clearSidebar = () => ({
  type: types.CLEAR_SIDEBAR,
});

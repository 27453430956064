/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { sendMessageToSlack } from '../../lib/helper';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  async componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log(error.stack);
    sendMessageToSlack(error.stack);
    this.setState({ errorInfo });
  }

  render() {
    const { name, url } = this.props;

    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return (
        <div className="content content-fixed content-auth-alt">
          <div className="container ht-100p tx-center">
            <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
              <div className="wd-70p wd-sm-250 wd-lg-300 mg-b-15">
                <img src="/images/errorHandling.png" className="img-fluid" alt="" />
              </div>
              <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">
                some things went wrong
              </h1>
              <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">Oopps</h5>
              <p className="tx-color-03 mg-b-30">
                The server is unable to service your request due to maintenance downtime or capacity
                problems.
              </p>
              <div className="mg-b-40">
                <a href={url} className="btn btn-white bd-2 pd-x-30">
                  Back to {name}
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  name: PropTypes.string,
  url: PropTypes.string,
};

import React from 'react';

function LeftSide() {
  return (
    <div className="left-side">
      <div className="content-left-signup pd-x-20-f pd-y-20-f">
        <div className="left-bg d-none d-lg-block" />
        <div className="logo-main d-none d-lg-block">
          {/* <i className="iconinna-logo" /> */}
          <img src="/images/new-logo.png" width="70px" />
        </div>
        <div className="logo-main d-lg-none">
          {/* <i className="iconinna-logo-mobile" /> */}
          <img src="/images/new-logo.png" width="50px" />
        </div>
      </div>
    </div>
  );
}

export default LeftSide;

import * as types from '../actionTypes/labels';
import { toastError } from '../../lib/toastHelper';

const initialState = {
  data: [],
  isLoadingAdd: false,
  isLoadingDelete: false,
  dataLabelCustomer: null,
  deleteLabelCustomer: null,
  labelDelete: null,
  modifyLabel: null,
  notes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_LABELS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data,
      };
    }
    case types.FETCH_LABELS_FALED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
      };
    }
    case types.ADD_LABEL: {
      return {
        ...state,
        isLoadingAdd: true,
      };
    }
    case types.ADD_LABEL_SUCCESS: {
      const label = action.payload.data;
      const { data } = state;
      let dataUpdate = data;
      const labelIndex = data.findIndex(item => item.id === label.id);
      if (labelIndex >= 0) {
        dataUpdate[labelIndex] = label;
      } else {
        dataUpdate = [...[label], ...data];
      }
      return {
        ...state,
        data: dataUpdate,
        isLoadingAdd: false,
        modifyLabel: label,
      };
    }
    case types.ADD_LABEL_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        isLoadingAdd: false,
      };
    }
    case types.DELETE_LABEL: {
      return {
        ...state,
        isLoadingDelete: true,
        labelDelete: null,
      };
    }
    case types.DELETE_LABEL_SUCCESS: {
      const labelDelete = action.payload.data;
      const { data } = state;
      const removeLabel = data.filter(label => label.id !== labelDelete.id);
      return {
        ...state,
        isLoadingDelete: false,
        data: removeLabel,
        labelDelete,
      };
    }
    case types.DELETE_LABEL_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        isLoadingDelete: false,
      };
    }
    case types.ADD_LABEL_CUSTOMER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        dataLabelCustomer: data,
        deleteLabelCustomer: null,
      };
    }
    case types.ADD_LABEL_CUSTOMER_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
      };
    }
    case types.DELETE_LABEL_CUSTOMER: {
      return {
        ...state,
      };
    }
    case types.DELETE_LABEL_CUSTOMER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        deleteLabelCustomer: data,
        dataLabelCustomer: null,
      };
    }
    case types.DELETE_LABEL_CUSTOMER_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
      };
    }
    case types.FETCH_NOTES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        notes: data,
      };
    }
    case types.DELETE_NOTE_FAILED:
    case types.EDIT_NOTE_FAILED:
    case types.FETCH_NOTES_FAILED:
    case types.ADD_NOTE_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
      };
    }
    case types.ADD_NOTE_SUCCESS: {
      const { notes } = state;
      const { data } = action.payload;
      const notesUpdate = [...notes, ...[data]];
      return {
        ...state,
        notes: notesUpdate,
      };
    }
    case types.EDIT_NOTE_SUCCESS: {
      const { notes } = state;
      const { data } = action.payload;
      const findIndex = notes.findIndex(note => note.id === data.id);
      if (findIndex > -1) {
        notes[findIndex] = data;
      }
      return {
        ...state,
        notes,
      };
    }
    case types.DELETE_NOTE_SUCCESS: {
      const { notes } = state;
      const { data } = action.payload;
      const notesFilter = notes.filter(note => note.id !== data.id);
      return {
        ...state,
        notes: notesFilter,
      };
    }
    case types.CLEAR_DATA_LABEL_CUSTOMER: {
      return {
        ...state,
        dataLabelCustomer: null,
      };
    }
    default:
      return state;
  }
};

export default reducer;

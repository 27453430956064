/* eslint-disable no-empty */
import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as types from '../actionTypes/chatv2';
import {
  fetchConversation,
  fetchMessage,
  fetchHotelsFromMM,
  fetchNotification,
  getConversationDetail,
  fetchCustomers,
  googleTranslate,
} from '../../apis/chatv2';
import {
  fetchConversationSuccess,
  fetchConversationFailed,
  fetchMessengeSuccess,
  fetchMessengeFailed,
  fetchHotelsFromMMSuccess,
  fetchHotelsFromMMFailed,
  fetchNotificationSuccess,
  fetchNotificationFailed,
  markReadNotificationFailed,
  markReadNotificationSuccess,
  agentNotificationSocketSuccess,
  messengeSocketSuccess,
  messengeSocketNotExitConverstationSuccess,
  takeOverSocketSuccess,
  changeLanguageSuccess,
  changeLanguageFailed,
  fetchMessgeTransSuccess,
  fetchMessgeTransFailed,
} from '../actions/chatv2';
// import { toastSuccess, toastError } from '../../lib/toastHelper';
import { STATUS_CODE } from '../../constants';

function* processFetchConversation({ payload }) {
  try {
    const { query } = payload;
    const resp = yield call(fetchConversation, query);
    const state = yield select();
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      const { channel, hotel } = state.chatv2;
      const customerChannelIds = data.data.map(item => item.user.channel_id);
      const query = {
        hotelId: hotel.id,
        channel,
        customerChannelIds,
      };
      const resCustomer = yield call(fetchCustomers, query);
      if (resCustomer.status === 200) {
        const customers = resCustomer.data.data;
        const mapping = mapCustomerToConversation(data, customers);
        yield put(fetchConversationSuccess(mapping));
      } else {
        yield put(fetchConversationSuccess(data));
      }
    }
  } catch (error) {
    const { response } = error;
    yield put(fetchConversationFailed(response));
  }
}

function mapCustomerToConversation(conversations, customers) {
  conversations.data.map(item => {
    const find = customers.find(customer => customer.channel_id === item.user.channel_id);
    item.customer = find;
  });
  return conversations;
}

function* processFetchMessenge({ payload }) {
  try {
    const resp = yield call(fetchMessage, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchMessengeSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(fetchMessengeFailed(response));
  } finally {
    // hide loading
  }
}

function* processFetchHotels({ payload }) {
  try {
    const resp = yield call(fetchHotelsFromMM, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchHotelsFromMMSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(fetchHotelsFromMMFailed(response));
  }
}

function* processFetchNotification() {
  try {
    const resp = yield call(fetchNotification);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchNotificationSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(fetchNotificationFailed(response));
  }
}

function* processMarkReadNotificationSameHotelSameChannel({ payload }) {
  try {
    const { notiMarkRead } = payload.data;
    const state = yield select();
    const { hotel, ignoreId, channel } = state.chatv2;
    let { conversationShow } = state.chatv2;
    const memeberHaveNotification = conversationShow.find(member => {
      return (
        member.user.channel_id === notiMarkRead.customer_channel_id &&
        member.hotel_id === notiMarkRead.hotel_id
      );
    });
    if (memeberHaveNotification) {
      yield put(
        markReadNotificationSuccess({
          memberActive: memeberHaveNotification,
          messenge: null,
          messengeShow: [],
          conversationShow,
          ignoreId,
        }),
      );
    } else if (
      hotel.id === notiMarkRead.hotel_id &&
      notiMarkRead.channel === channel &&
      conversationShow.length > 0
    ) {
      const customerChannelId = notiMarkRead.customer_channel_id;
      const query = {
        hotelId: notiMarkRead.hotel_id,
        channel: notiMarkRead.channel,
        customerChannelIds: [customerChannelId],
        ignoreId: [],
      };
      try {
        const response = yield call(fetchConversation, query);
        const { data, status } = response;
        if (status) {
          const memberNoti = data.data[0];
          conversationShow = [memberNoti, ...conversationShow];
          yield put(
            markReadNotificationSuccess({
              memberActive: memberNoti,
              conversationShow,
              ignoreId: [...ignoreId, memberNoti.id],
            }),
          );
        }
      } catch (error) {}
    }
  } catch (error) {
    const { response } = error;
    yield put(markReadNotificationFailed(response));
  }
}

function* processAgentNotificationSocket({ payload }) {
  try {
    const { data } = payload;
    const state = yield select();
    const { notifications } = state.chatv2;
    let notificationChange;
    if (data.resolved_by_user_id) {
      // remove
      notificationChange = notifications.filter(noti => noti.id !== data.id);
    } else {
      // addList
      notificationChange = [data, ...notifications];
    }
    yield put(agentNotificationSocketSuccess(notificationChange));
  } catch (error) {}
}

function* processMessengeSocket({ payload }) {
  try {
    const state = yield select();
    const { conversationShow, hotel, channel } = state.chatv2;
    const { data } = payload;
    if (data.conversation.hotel_id === hotel.id && data.message_raw.channelId === channel) {
      const check = conversationShow.find(item => item.id === data.conversation_id);
      if (check) {
        yield put(messengeSocketSuccess(data));
      } else {
        yield put({
          type: types.MESSENGE_SOCKET_V2_NOT_EXIT_CONVERSTATION,
          payload: { query: data },
        });
      }
    }
  } catch (error) {}
}

function* processMessengeSocketNotExitConverstation({ payload }) {
  try {
    const { query } = payload;
    const id = query.conversation_id;
    const resp = yield call(getConversationDetail, id);
    const { data, status } = resp;
    if (status === 200) {
      const state = yield select();
      const customerChannelIds = [data.data.user.channel_id];
      const { channel, hotel } = state.chatv2;
      const query = {
        hotelId: hotel.id,
        page: 1,
        channel,
        customerChannelIds,
      };
      const resCustomer = yield call(fetchCustomers, query);
      if (resCustomer && resCustomer.data.data.length > 0) {
        data.data.customer = resCustomer.data.data[0];
      }
      const conversation = data.data;
      yield put(messengeSocketNotExitConverstationSuccess(conversation));
    }
  } catch (error) {}
}

function* processTakeOverSocket({ payload }) {
  try {
    const { data } = payload;
    const state = yield select();
    const { memberActive, conversationShow } = state.chatv2;
    let isEmptyList = false;
    if (memberActive && data.channel_id === memberActive.user.channel_id) {
      memberActive.customer = data;
      isEmptyList = true;
    }
    const conversation = conversationShow.find(item => item.user.channel_id === data.channel_id);
    if (conversation) {
      conversation.customer = data;
    }
    yield put(takeOverSocketSuccess({ memberActive, conversationShow, isEmptyList }));
  } catch (error) {}
}

function* processFilterConversation({ payload }) {
  try {
    const { query } = payload;
    const resp = yield call(fetchCustomers, query);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      const state = yield select();
      const { channel, hotel } = state.chatv2;
      if (data.data.length > 0) {
        const customerChannelIds = data.data.map(item => item.channel_id);
        const resConversations = yield call(fetchConversation, {
          hotelId: hotel.id,
          channel,
          customerChannelIds,
        });
        const mapping = mapCustomerToConversation(resConversations.data, data.data);
        yield put(fetchConversationSuccess(mapping));
      } else {
      }
    }
  } catch (error) {}
}

async function translateTextMessenger(query) {
  const { cloneMess, defaultLanguage, lang } = query;
  const cloneMessLength = cloneMess.length;
  const requests = [];
  if (cloneMessLength > 60) {
    for (let i = 0; i < cloneMess.length; i += 60) {
      const messenge = cloneMess.slice(i, i + 60);
      const resp = translateMessenge({ messenge, defaultLanguage, translateTo: lang });
      requests.push(resp);
    }
    return Promise.all(requests);
  }
  return translateMessenge({ messenge: cloneMess, defaultLanguage, translateTo: lang });
}

async function translateMessenge(query) {
  const { messenge, defaultLanguage, translateTo } = query;
  const cloneRaw = JSON.parse(JSON.stringify(messenge));
  const rawText = cloneRaw.map(mess => mess.message_raw.text || '');
  const dataTrans = makeTrans(rawText, defaultLanguage, translateTo);
  const resp = await googleTranslate(dataTrans);
  return resp;
}

function makeTrans(q, source, target) {
  return {
    q,
    // source,
    target,
    format: 'text',
  };
}

function* processChangeLanguage({ payload }) {
  try {
    const { lang } = payload;
    const state = yield select();
    const { defaultLanguage, messengeShow } = state.chatv2;
    const cloneMess = JSON.parse(JSON.stringify(messengeShow));
    const trans = yield call(translateTextMessenger, { cloneMess, defaultLanguage, lang });
    // const getPayload = messengeShow.map(mess => mess.payload);
    let translateData = [];
    if (trans.length > 0) {
      translateData = trans
        .map(tran => tran.data.data.translations)
        .reduce((acc, val) => [...acc, ...val]);
    } else {
      translateData = trans.data.data.translations;
    }
    const textTranslate = translateData.map(tran => tran.translatedText);
    const translations = cloneMess.map((mess, index) => {
      mess.message_raw.text = textTranslate[index];
      return mess;
    });
    yield put(changeLanguageSuccess({ translations, lang }));
  } catch (error) {
    const { response } = error;
    const { message } = response.data.error;
    yield put(changeLanguageFailed(message));
  }
}

function* processFetchMessengeTrans({ payload }) {
  try {
    const state = yield select();
    const { messenge, defaultLanguage, translateTo } = state.chatv2;
    const resp = yield call(translateMessenge, {
      messenge: messenge.data,
      defaultLanguage,
      translateTo,
    });
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      const cloneMess = JSON.parse(JSON.stringify(messenge.data));
      const result = data.data.translations;
      const textTranslate = result.map(tran => tran.translatedText);
      const translations = cloneMess.map((mess, index) => {
        mess.message_raw.text = textTranslate[index];
        return mess;
      });
      yield put(fetchMessgeTransSuccess(translations));
    }
  } catch (error) {
    const { response } = error;
    const { message } = response.data.error;
    yield put(fetchMessgeTransFailed(message));
  }
}

function* chatv2Saga() {
  yield takeLatest(types.FETCH_CONVERSATION, processFetchConversation);
  yield takeLatest(types.FETCH_MESSENGE_V2, processFetchMessenge);
  yield takeLatest(types.FETCH_HOTELS_FROM_MM, processFetchHotels);
  yield takeLatest(types.FETCH_NOTIFICATION, processFetchNotification);
  yield takeLatest(
    types.MARK_READ_NOTIFICATION_SAME_HOTEL_SAME_CHANNEL,
    processMarkReadNotificationSameHotelSameChannel,
  );
  yield takeLatest(types.AGENT_NOTIFICATIONS_SOCKET, processAgentNotificationSocket);
  yield takeLatest(types.MESSENGE_SOCKET_V2, processMessengeSocket);
  yield takeLatest(
    types.MESSENGE_SOCKET_V2_NOT_EXIT_CONVERSTATION,
    processMessengeSocketNotExitConverstation,
  );
  yield takeLatest(types.TAKE_OVER_SOCKET, processTakeOverSocket);
  yield takeLatest(types.FILTER_CONVERSTATION, processFilterConversation);
  yield takeLatest(types.CHANGE_LANGUAGE, processChangeLanguage);
  yield takeLatest(types.FETCH_MESSENGE_TRANS, processFetchMessengeTrans);
}

export default chatv2Saga;

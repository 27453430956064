import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DropdownToggle, ButtonDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import * as Icon from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import { DASHBOARD_TYPE } from '../../../../constants';

export default function DropDownHotels({ type }) {
  const [dropdownOpen, setOpen] = useState(false);
  const hotel = useSelector(state => state.auth.hotel);
  const hotels = useSelector(state => state.auth.hotels);
  const history = useHistory();

  const redirectHotel = hotelSlug => {
    if (type === DASHBOARD_TYPE.HOTEL_ROOMS) {
      const isAdmin = hotel.userRole.is_admin;
      if (isAdmin === 0) {
        window.location.href = `/${hotelSlug}/order-history/vouchers`;
      } else {
        window.location.href = `/${hotelSlug}/dashboard`;
      }
    } else if (type === DASHBOARD_TYPE.IN_STAY_SERVICE) {
      window.location.href = `/${hotelSlug}/in-stay-service`;
    } else if (type === DASHBOARD_TYPE.LIVE_CHAT) {
      window.location.href = `/${hotelSlug}/chat`;
    }
  };

  return (
    <div className="navbar-brand">
      <div className="">
        <img src="/images/new-logo.png" width="40px" alt="logo" />
      </div>
      {hotels && hotels.length > 0 && (
        <ButtonDropdown
          isOpen={dropdownOpen}
          toggle={() => setOpen(!dropdownOpen)}
          className="mg-l-20 header_list_hotel"
        >
          <DropdownToggle color="" className="tx-14 tx-medium btn-xs d-flex align-items-center">
            <span className="text-primary pd-r-20">{hotel?.name}</span>
            <Icon.ChevronDown className="text-primary" />
          </DropdownToggle>
          <DropdownMenu>
            {hotels.length > 0 &&
              hotels.map(hotel => {
                return (
                  <DropdownItem onClick={() => redirectHotel(hotel.slug)} key={hotel.id}>
                    {hotel.name}
                  </DropdownItem>
                );
              })}
            <DropdownItem
              onClick={() => history.push('/onboard')}
              key={0}
              className="bg-primary color-white-f text-center "
            >
              Add new hotel
              <Icon.Plus size={16} />
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      )}
    </div>
  );
}

DropDownHotels.propTypes = {
  type: PropTypes.string,
};

import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import configureStore, { history } from '@Store/configureStore';
import Routes from './components/Routes';

const queryClient = new QueryClient();

const store = configureStore();
const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </QueryClientProvider>
  </Provider>
);

export default App;

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actionTypes/labels';
import {
  getLabels,
  addLabel,
  deleteLabel,
  addLabelCustomer,
  deleteLabelCustomer,
  fetchNotes,
  addNote,
  editNote,
  deleteNote,
} from '../../apis/labels';
import {
  fetchLabelsSuccess,
  fetchLabelsFailed,
  addLabelSuccess,
  addLabelFailed,
  deleteLabelSuccess,
  deleteLabelFailed,
  addLabelCustomerSuccess,
  addLabelCustomerFailed,
  deleteLabelCustomerSuccess,
  deleteLabelCustomerFailed,
  fetchNotesSuccess,
  fetchNotesFailed,
  addNoteFailed,
  addNoteSuccess,
  editNoteSuccess,
  editNoteFailed,
  deleteNoteSuccess,
  deleteNoteFailed,
} from '../actions/labels';
import { STATUS_CODE } from '../../constants';

function* processFetchLabels() {
  try {
    const resp = yield call(getLabels);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchLabelsSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(fetchLabelsFailed(response));
  }
}

function* processAddNew({ payload }) {
  try {
    const resp = yield call(addLabel, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(addLabelSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(addLabelFailed(response));
  }
}

function* processDeleteLabel({ payload }) {
  try {
    const resp = yield call(deleteLabel, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteLabelSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(deleteLabelFailed(response));
  }
}

function* processAddLabelCustomer({ payload }) {
  try {
    const resp = yield call(addLabelCustomer, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(addLabelCustomerSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(addLabelCustomerFailed(response));
  }
}

function* processDeleteLabelCustomer({ payload }) {
  try {
    const resp = yield call(deleteLabelCustomer, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteLabelCustomerSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(deleteLabelCustomerFailed(response));
  }
}

function* processFetchNotes({ payload }) {
  try {
    const resp = yield call(fetchNotes, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchNotesSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(fetchNotesFailed(response));
  }
}

function* processAddNote({ payload }) {
  try {
    const resp = yield call(addNote, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(addNoteSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(addNoteFailed(response));
  }
}

function* processEditNote({ payload }) {
  try {
    const resp = yield call(editNote, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(editNoteSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(editNoteFailed(response));
  }
}

function* processDeleteNote({ payload }) {
  try {
    const resp = yield call(deleteNote, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteNoteSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    yield put(deleteNoteFailed(response));
  }
}

function* labelsSaga() {
  yield takeLatest(types.FETCH_LABELS, processFetchLabels);
  yield takeLatest(types.ADD_LABEL, processAddNew);
  yield takeLatest(types.DELETE_LABEL, processDeleteLabel);
  yield takeLatest(types.ADD_LABEL_CUSTOMER, processAddLabelCustomer);
  yield takeLatest(types.DELETE_LABEL_CUSTOMER, processDeleteLabelCustomer);
  yield takeLatest(types.FETCH_NOTES, processFetchNotes);
  yield takeLatest(types.ADD_NOTE, processAddNote);
  yield takeLatest(types.EDIT_NOTE, processEditNote);
  yield takeLatest(types.DELETE_NOTE, processDeleteNote);
}

export default labelsSaga;

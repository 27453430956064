export const STATUS_CODE = {
  SUCCESS: 200,
  CREATED: 201,
  UPDATED: 202,
};

export const COLOR = {
  GRAY: '#90949c',
  RED: '#f65569',
  BLUE: '#5291ff',
  ORANGE: '#fc9240',
  GREEN: '#72a737',
  PURPLE: '#8a73c9',
};

export const DASHBOARD_TYPE = {
  HOTEL_ROOMS: 'Hotel & room',
  IN_STAY_SERVICE: 'In-stay service',
  LIVE_CHAT: 'Live chat',
};

import * as types from '../actionTypes/auth';

export const login = (email, password) => ({
  type: types.LOGIN,
  payload: {
    email,
    password,
  },
});

export const loginSuccess = data => ({
  type: types.LOGIN_SUCCESS,
  payload: {
    data,
  },
});

export const loginFailed = error => ({
  type: types.LOGIN_FAILED,
  payload: {
    error,
  },
});

export const getInfo = hotelSlug => ({
  type: types.GET_INFO,
  payload: {
    hotelSlug,
  },
});

export const getInfoSuccess = data => ({
  type: types.GET_INFO_SUCCESS,
  payload: {
    data,
  },
});

export const getInfoFailed = error => ({
  type: types.GET_INFO_FAILED,
  payload: {
    error,
  },
});

export const setHotelActive = hotelSlug => ({
  type: types.SET_HOTEL_ACTIVE,
  payload: {
    hotelSlug,
  },
});

export const setHotelActiveSuccess = data => ({
  type: types.SET_HOTEL_ACTIVE_SUCCESS,
  payload: {
    data,
  },
});

export const notifyHotel = data => ({
  type: types.NOTIFY_HOTEL,
  payload: {
    data,
  },
});

export const notifyHotelSuccess = data => ({
  type: types.NOTIFY_HOTEL_SUCCESS,
  payload: {
    data,
  },
});

export const updateOnboardHotel = data => ({
  type: types.UPDATE_ONBOARD_HOTEL,
  payload: {
    data,
  },
});

export const updateOnboardHotelSuccess = data => ({
  type: types.UPDATE_ONBOARD_HOTEL_SUCCESS,
  payload: {
    data,
  },
});

export const updateCurrencySetting = data => ({
  type: types.UPDATE_CURRENCY_SETTING,
  payload: {
    data,
  },
});

export const updateCurrencySettingSuccess = data => ({
  type: types.UPDATE_CURRENCY_SETTING_SUCCESS,
  payload: {
    data,
  },
});

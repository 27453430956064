import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as types from '../actionTypes/chat';
import {
  fetchHotelsMMSuccess,
  fetchConversationsSuccess,
  fetchMessengesSuccess,
  messengeSocketSuccess,
  fetchNotificationsSuccess,
  takeOverSocketSuccess,
  messengeSocketNotExitConverstationSuccess,
  markReadNotificationsSuccess,
  agentNotificationSocketSuccess,
  changeLanguageSuccess,
  setIgnoreConversationId,
} from '../actions/chat';
import {
  fetchHotelsMM,
  fetchCustomers,
  fetchConversation,
  fetchMessages,
  fetchNotifications,
  getConversationDetail,
} from '../../apis/chat';
import { STATUS_CODE } from '../../constants';
import { toastError, toastFailed } from '../../lib/toastHelper';
import {
  mapCustomerToConversation,
  checkNotificationExitHotels,
  translateTextMessenger,
  changeMessageRawTranslate,
} from '../../lib/livechat';
import { sendMessageToSlack } from '../../lib/helper';

function* processHotelsMM({ payload }) {
  try {
    const { query } = payload;
    const resp = yield call(fetchHotelsMM, query);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      const state = yield select();
      const { hotel } = state.auth;
      const hotelsMM = data.data;


      if (hotel.fb_page_id === null && hotel.line_account_id === null) {
        toastError('Invalid hotel data');
        return;
      }

      let hotelMM = null;

      if (hotel.fb_page_id) {
        hotelMM = hotelsMM.find(item => item.fb_page_id == hotel.fb_page_id);
      }

      if (!hotelMM && hotel.line_account_id) {
        hotelMM = hotelsMM.find(item => item.line_azure_channel_registration_id == hotel.line_account_id);
      }

      if (hotelMM) {
        yield put(fetchHotelsMMSuccess({ hotelsMM, hotelMM }));
      } else {
        toastError('No matching hotel found');
      }
    }
  } catch (error) {
    toastError('An error occurred while loading the hotel list');
  }
}

function* processConversations({ payload }) {
  try {
    const { query } = payload;
    const resConversation = yield call(fetchConversation, query);
    const { data: conversations, status } = resConversation;
    if (status === STATUS_CODE.SUCCESS) {
      const state = yield select();
      const { channel, hotel } = state.chat;
      if (hotel) {
        const customerChannelIds = conversations.data.map(item => item.user.channel_id);
        const queryCustomer = {
          hotelId: hotel.id,
          channel,
          customerChannelIds,
        };
        if (query.tag) {
          queryCustomer.tag = query.tag;
        }
        const resCustomer = yield call(fetchCustomers, queryCustomer);
        const { status: statusCustomer } = resCustomer;
        if (statusCustomer === STATUS_CODE.SUCCESS) {
          const customers = resCustomer.data.data;
          const conversationMapping = mapCustomerToConversation(conversations, customers);
          yield put(fetchConversationsSuccess(conversationMapping));
        }
      }
    }
  } catch (error) {
    const { response } = error;
    toastError(response);
  }
}

function* processFetchMessenges({ payload }) {
  try {
    const resp = yield call(fetchMessages, payload);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      const state = yield select();
      const { translateTo } = state.chat;
      if (translateTo) {
        const trans = yield call(translateTextMessenger, {
          messenges: data.data,
          translateTo,
        });
        const translations = changeMessageRawTranslate(trans, data.data);
        data.data = translations;
      }
      yield put(fetchMessengesSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    toastError(response);
  }
}

function* processMessengeSocket({ payload }) {
  try {
    const state = yield select();
    const {
      conversationsShow,
      hotel,
      channel,
      memberActive,
      messengesShow,
      translateTo,
    } = state.chat;
    const { data: socket } = payload;
    if (socket.conversation.hotel_id === hotel.id && socket.message_raw.channelId === channel) {
      // Check dataSocket is exits conversationShow
      const check = conversationsShow.find(item => item.id === socket.conversation_id);
      if (check) {
        if (memberActive && socket.conversation_id === memberActive.id) {
          let newMessengesShow = [];
          const findIndexMessExit = messengesShow.findIndex(
            mess => mess.message_raw.text === socket.message_raw.text && mess.replica,
          );
          if (translateTo) {
            const trans = yield call(translateTextMessenger, {
              messenges: [socket],
              translateTo,
            });
            if (trans.status === 200) {
              socket.message_raw.text = trans.data.data.translations[0].translatedText;
            }
          }
          if (findIndexMessExit >= 0) {
            messengesShow[findIndexMessExit] = socket;
            const removeDup = JSON.parse(JSON.stringify(messengesShow));
            newMessengesShow = removeDup
              .filter(item => !item.replica)
              .sort((a, b) => a.created_at > b.created_at);
          } else {
            newMessengesShow = [...messengesShow, socket];
          }
          yield put(messengeSocketSuccess(newMessengesShow));
        }
      } else {
        yield put({
          type: types.MESSENGE_SOCKET_NOT_EXIT_CONVERSTATION,
          payload: { query: socket },
        });
      }
    }
  } catch (error) {
    sendMessageToSlack(error.stack);
  }
}

function* processFetchNotifications() {
  try {
    const resp = yield call(fetchNotifications);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchNotificationsSuccess(data));
    }
  } catch (error) {
    const { response } = error;
    toastError(response);
  }
}

function* processTakeOverSocket({ payload }) {
  try {
    const { data } = payload;
    const state = yield select();
    const { memberActive, conversationsShow } = state.chat;
    if (memberActive && data.channel_id === memberActive.user.channel_id) {
      memberActive.customer = data;
    }
    const conversation = conversationsShow.find(item => item.user.channel_id === data.channel_id);
    if (conversation) {
      conversation.customer = data;
    }
    yield put(takeOverSocketSuccess({ memberActive, conversationsShow }));
  } catch (error) {
    sendMessageToSlack(error.stack);
  }
}

function* processFilterConversations({ payload }) {
  try {
    const { query } = payload;
    const resp = yield call(fetchCustomers, query);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      const state = yield select();
      const { channel, hotel } = state.chat;
      if (data.data.length > 0) {
        const customerChannelIds = data.data.map(item => item.channel_id);
        const resConversations = yield call(fetchConversation, {
          hotelId: hotel.id,
          channel,
          customerChannelIds,
        });
        const mapping = mapCustomerToConversation(resConversations.data, data.data);
        yield put(fetchConversationsSuccess(mapping));
      }
    }
  } catch (error) {
    toastError('processFilterConversations');
  }
}

function* processMessengeSocketNotExitConverstation({ payload }) {
  try {
    const { query } = payload;
    const id = query.conversation_id;
    const resp = yield call(getConversationDetail, id);
    const { data, status } = resp;
    if (status === 200) {
      const state = yield select();
      if (data.data && data.data.user) {
        const customerChannelIds = [data.data.user.channel_id];
        const { channel, hotel } = state.chat;
        const query = {
          hotelId: hotel.id,
          page: 1,
          channel,
          customerChannelIds,
        };
        const resCustomer = yield call(fetchCustomers, query);
        if (resCustomer && resCustomer.data.data.length > 0) {
          data.data.customer = resCustomer.data.data[0];
        }
        const conversation = data.data;
        yield put(messengeSocketNotExitConverstationSuccess(conversation));
      }
    }
  } catch (error) {
    sendMessageToSlack(error.stack);
  }
}

function* processMarkReadNotificationSameHotelSameChannel({ payload }) {
  try {
    const { notiMarkRead } = payload.data;
    const state = yield select();
    const { hotel, channel, ignoreConversationId } = state.chat;
    let { conversationsShow } = state.chat;
    const memeberHaveNotification = conversationsShow.find(member => {
      return (
        member.user.channel_id === notiMarkRead.customer_channel_id &&
        member.hotel_id === notiMarkRead.hotel_id
      );
    });
    if (memeberHaveNotification) {
      yield put(
        markReadNotificationsSuccess({
          memberActive: { ...memeberHaveNotification },
          conversationsShow,
          ignoreConversationId,
        }),
      );
    } else if (
      hotel.id === notiMarkRead.hotel_id &&
      notiMarkRead.channel === channel &&
      conversationsShow.length > 0
    ) {
      const customerChannelId = notiMarkRead.customer_channel_id;
      const query = {
        hotelId: notiMarkRead.hotel_id,
        channel: notiMarkRead.channel,
        customerChannelIds: [customerChannelId],
        ignoreId: [],
      };
      try {
        const response = yield call(fetchConversation, query);
        const { data, status } = response;
        if (status) {
          const memberNoti = data.data[0];
          conversationsShow = [memberNoti, ...conversationsShow];
          yield put(
            markReadNotificationsSuccess({
              memberActive: memberNoti,
              conversationsShow,
              ignoreConversationId: [...ignoreConversationId, memberNoti.id],
            }),
          );
        }
      } catch (error) {
        sendMessageToSlack(error.stack);
      }
    }
  } catch (error) {
    sendMessageToSlack(error.stack);
  }
}

function* processAgentNotificationSocket({ payload }) {
  try {
    const { data } = payload;
    const state = yield select();
    const { notifications, hotels } = state.chat;
    const check = checkNotificationExitHotels(data.hotel_id, hotels);
    if (check) {
      let notificationChange;
      if (data.resolved_by_user_id) {
        // remove
        notificationChange = notifications.filter(noti => noti.id !== data.id);
      } else {
        // addList
        notificationChange = [data, ...notifications];
      }
      yield put(agentNotificationSocketSuccess(notificationChange));
    }
  } catch (error) {
    sendMessageToSlack(error.stack);
  }
}

function* processChangeLanguage({ payload }) {
  try {
    const { lang } = payload;
    const state = yield select();
    const { messengesShow } = state.chat;
    const trans = yield call(translateTextMessenger, {
      messenges: messengesShow,
      translateTo: lang,
    });
    const translations = changeMessageRawTranslate(trans, messengesShow);
    yield put(changeLanguageSuccess({ translations, lang }));
  } catch (error) {
    sendMessageToSlack(error.stack);
  }
}

function* processFetchCustomerNotExitConverstation({ payload }) {
  try {
    const state = yield select();
    const { channel, hotel, conversations } = state.chat;
    const { customerChannelId } = payload;
    const query = {
      hotelId: hotel.id,
      page: 1,
      channel,
      customerChannelIds: [customerChannelId],
    };
    const resCustomers = yield call(fetchCustomers, query);
    const resConversations = yield call(fetchConversation, query);
    const mapping = mapCustomerToConversation(resConversations.data, resCustomers.data.data);
    const conversationNotExit = mapping.data;
    const converstionsShow = [...conversations.data, ...conversationNotExit];
    conversations.data = converstionsShow;
    yield put(setIgnoreConversationId(resConversations.data.data[0].id));
    yield put(fetchConversationsSuccess(conversations));
  } catch (error) {
    sendMessageToSlack(error.stack);
  }
}

function* chatSaga() {
  yield takeLatest(types.FETCH_HOTELS_MM, processHotelsMM);
  yield takeLatest(types.FETCH_CONVERSATIONS, processConversations);
  yield takeLatest(types.FETCH_MESSENGES, processFetchMessenges);
  yield takeLatest(types.MESSENGE_SOCKET, processMessengeSocket);
  yield takeLatest(types.FETCH_NOTIFICATIONS, processFetchNotifications);
  yield takeLatest(types.TAKE_OVER_SOCKET, processTakeOverSocket);
  yield takeLatest(types.FILTER_CONVERSTATIONS, processFilterConversations);
  yield takeLatest(
    types.MESSENGE_SOCKET_NOT_EXIT_CONVERSTATION,
    processMessengeSocketNotExitConverstation,
  );
  yield takeLatest(
    types.MARK_READ_NOTIFICATION_SAME_HOTEL_SAME_CHANNEL,
    processMarkReadNotificationSameHotelSameChannel,
  );
  yield takeLatest(types.AGENT_NOTIFICATIONS_SOCKET, processAgentNotificationSocket);
  yield takeLatest(types.CHANGE_LANGUAGE, processChangeLanguage);
  yield takeLatest(types.CUSTOMER_NOT_EXIT_CONVERSTATION, processFetchCustomerNotExitConverstation);
}

export default chatSaga;

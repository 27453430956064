import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Icon from 'react-feather';
import { DropdownToggle, ButtonDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import axiosService from '@Lib/axiosService';

export default function UserControl() {
  const [dropdownOpen, setOpen] = useState(false);
  const user = useSelector(state => state.auth.user);
  const logOut = () => {
    axiosService.removeHeader('Authorization');
    localStorage.removeItem(process.env.TOKEN);
    window.location.href = '/login';
  };
  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={() => setOpen(!dropdownOpen)}
      className="dropdown-menu-right animated flipInX"
    >
      <DropdownToggle nav caret className="dropdown-toggle-nocaret">
        {user && user.avatar ? (
          <img className="mg-x-10" src={user.avatar} alt="avatar" />
        ) : (
          <i className="iconinna-no-avatar mg-x-10" />
        )}
        {user && (user.name || user.email)}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={() => logOut()}>
          <Icon.LogOut size={16} /> Log Out
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

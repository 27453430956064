import axiosService from '../lib/axiosService';

const api = process.env.API_ENDPOINT;

export const fetchOrders = query => {
  const { hotelSlug, ...newQuery } = query;
  const url = `${api}/v2/hotels/${hotelSlug}/in-stay-service-orders`;
  return axiosService.get(url, newQuery);
};

export const fetchOrdersStatus = query => {
  const { hotelSlug } = query;
  const url = `${api}/v2/hotels/${hotelSlug}/in-stay-service-orders/count`;
  return axiosService.get(url, query);
};

export const fetchOrderDetail = query => {
  const { hotelSlug, detailId } = query;
  const url = `${api}/v2/hotels/${hotelSlug}/in-stay-service-orders/${detailId}`;
  return axiosService.get(url);
};

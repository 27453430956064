import React from 'react';
import PropsType from 'prop-types';
import GlobalLoading from '../../GlobalLoading';
import '../../../language/i18n';

function WebviewLayout(props) {
  const { children } = props;
  return (
    <div className="botman">
      {children}
      <GlobalLoading />
    </div>
  );
}

WebviewLayout.propTypes = {
  children: PropsType.object,
};

export default WebviewLayout;

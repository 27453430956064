import * as types from '../actionTypes/chat';

export const fetchHotelsMM = query => ({
  type: types.FETCH_HOTELS_MM,
  payload: {
    query,
  },
});

export const fetchHotelsMMSuccess = data => ({
  type: types.FETCH_HOTELS_MM_SUCCESS,
  payload: {
    data,
  },
});

export const fetchConversations = query => ({
  type: types.FETCH_CONVERSATIONS,
  payload: {
    query,
  },
});

export const fetchConversationsSuccess = data => ({
  type: types.FETCH_CONVERSATIONS_SUCCESS,
  payload: {
    data,
  },
});

export const clearConversations = () => ({
  type: types.CLEAR_CONVERSATIONS,
});

export const setMemberActive = data => ({
  type: types.SET_MEMBER_ACTIVE,
  payload: {
    data,
  },
});

export const fetchMessenges = (conversationId, page) => ({
  type: types.FETCH_MESSENGES,
  payload: {
    conversationId,
    page,
  },
});

export const fetchMessengesSuccess = data => ({
  type: types.FETCH_MESSENGES_SUCCESS,
  payload: {
    data,
  },
});

export const setCurrentChannel = data => ({
  type: types.SET_CURRENT_CHANNEL,
  payload: {
    data,
  },
});

export const messengeSocket = data => ({
  type: types.MESSENGE_SOCKET,
  payload: {
    data,
  },
});

export const messengeSocketSuccess = data => ({
  type: types.MESSENGE_SOCKET_SUCCESS,
  payload: {
    data,
  },
});

export const setConversationStatus = data => ({
  type: types.SET_CONVERSATION_STATUS,
  payload: {
    data,
  },
});

export const setHotelMMStatus = data => ({
  type: types.SET_HOTEL_MM_STATUS,
  payload: {
    data,
  },
});

export const fetchNotifications = query => ({
  type: types.FETCH_NOTIFICATIONS,
  payload: {
    query,
  },
});

export const fetchNotificationsSuccess = data => ({
  type: types.FETCH_NOTIFICATIONS_SUCCESS,
  payload: {
    data,
  },
});

export const takeOverSocket = data => ({
  type: types.TAKE_OVER_SOCKET,
  payload: {
    data,
  },
});

export const takeOverSocketSuccess = data => ({
  type: types.TAKE_OVER_SOCKET_SUCCESS,
  payload: {
    data,
  },
});

export const filterConversations = query => ({
  type: types.FILTER_CONVERSTATIONS,
  payload: {
    query,
  },
});

export const filterConversationsSuccess = data => ({
  type: types.FILTER_CONVERSTATIONS_SUCCESS,
  payload: {
    data,
  },
});

export const messengeSocketNotExitConverstation = query => ({
  type: types.MESSENGE_SOCKET_NOT_EXIT_CONVERSTATION,
  payload: {
    query,
  },
});

export const messengeSocketNotExitConverstationSuccess = data => ({
  type: types.MESSENGE_SOCKET_NOT_EXIT_CONVERSTATION_SUCCESS,
  payload: {
    data,
  },
});

export const markReadNotificationSameHotelSameChannel = data => ({
  type: types.MARK_READ_NOTIFICATION_SAME_HOTEL_SAME_CHANNEL,
  payload: {
    data,
  },
});

export const markReadNotificationsSuccess = data => ({
  type: types.MARK_READ_NOTIFICATIONS_SUCCESS,
  payload: {
    data,
  },
});

export const agentNotificationSocket = data => ({
  type: types.AGENT_NOTIFICATIONS_SOCKET,
  payload: {
    data,
  },
});

export const agentNotificationSocketSuccess = data => ({
  type: types.AGENT_NOTIFICATIONS_SOCKET_SUCCESS,
  payload: {
    data,
  },
});

export const addNewMess = data => ({
  type: types.ADD_NEW_MESS,
  payload: {
    data,
  },
});

export const addNewMessFailed = id => ({
  type: types.ADD_NEW_MESS_FAILED,
  payload: {
    id,
  },
});

export const setCopyFaq = data => ({
  type: types.SET_COPY_FAQ,
  payload: {
    data,
  },
});

export const changeLanguage = lang => ({
  type: types.CHANGE_LANGUAGE,
  payload: {
    lang,
  },
});

export const changeLanguageSuccess = data => ({
  type: types.CHANGE_LANGUAGE_SUCCESS,
  payload: {
    data,
  },
});

export const setDefaultLanguage = () => ({
  type: types.SET_DEFAULT_LANGUAGE,
});

export const changeLanguageToSend = lang => ({
  type: types.CHANGE_LANGUAGE_TO_SEND,
  payload: {
    lang,
  },
});

export const fetchCustomerNotExitConverstation = customerChannelId => ({
  type: types.CUSTOMER_NOT_EXIT_CONVERSTATION,
  payload: {
    customerChannelId,
  },
});

export const setGuestId = customerChannelId => ({
  type: types.SET_GUEST_ID,
  payload: {
    customerChannelId,
  },
});

export const setIgnoreConversationId = ignoreId => ({
  type: types.SET_IGNORE_CONVERSATION_ID,
  payload: {
    ignoreId,
  },
});

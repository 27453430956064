import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import PublicLayout from '../Layout/PublicLayout';
import axiosService from '../../lib/axiosService';

function PublicRoutes(props) {
  const { component: YourComponent, ...remainProps } = props;
  const token = localStorage.getItem(process.env.TOKEN);
  if (token) {
    axiosService.setHeader('Authorization', `Bearer ${token}`);
  }
  return (
    <Route
      {...remainProps}
      render={routeProps => {
        return (
          <PublicLayout>
            <YourComponent {...routeProps} />
          </PublicLayout>
        );
      }}
    />
  );
}

PublicRoutes.propTypes = {
  component: PropTypes.object,
};

export default PublicRoutes;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import axiosService from '../../lib/axiosService';
import LiveChatLayout from '../Layout/LiveChatLayout';

function LiveChatRoutes(props) {
  const { component: YourComponent, ...remainProps } = props;
  const token = localStorage.getItem(process.env.TOKEN);
  if (token) {
    axiosService.setHeader('Authorization', `Bearer ${token}`);
  }
  return (
    <Route
      {...remainProps}
      render={routeProps => {
        return token ? (
          <LiveChatLayout>
            <YourComponent {...routeProps} />
          </LiveChatLayout>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
}

LiveChatRoutes.propTypes = {
  component: PropTypes.object,
};

export default LiveChatRoutes;

export const FETCH_CONVERSATION = 'FETCH_CONVERSATION';
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const FETCH_CONVERSATION_FAILED = 'FETCH_CONVERSATION_FAILED';

export const SET_MEMBER_ACTIVE_V2 = 'SET_MEMBER_ACTIVE_V2';

export const FETCH_MESSENGE_V2 = 'FETCH_MESSENGE_V2';
export const FETCH_MESSENGE_SUCCESS_V2 = 'FETCH_MESSENGE_SUCCESS_V2';
export const FETCH_MESSENGE_FAILED_V2 = 'FETCH_MESSENGE_FAILED_V2';

export const ADD_NEW_MESS = 'ADD_NEW_MESS_V2';
export const ADD_NEW_MESS_FAILED = 'ADD_NEW_MESS_FAILED_V2';

export const CHANGE_CHANNEL = 'CHANGE_CHANNEL';

export const FETCH_HOTELS_FROM_MM = 'FETCH_HOTELS_FROM_MM';
export const FETCH_HOTELS_FROM_MM_SUCCESS = 'FETCH_HOTELS_FROM_MM_SUCCESS';
export const FETCH_HOTELS_FROM_MM_FAILED = 'FETCH_HOTELS_FROM_MM_FAILED';

export const SET_HOTEL_MM_ACTIVE = 'SET_HOTEL_MM_ACTIVE';

export const UPDATE_CONVERSATION_STATUS = 'UPDATE_CONVERSATION_STATUS';

export const UPDATE_HOTEL_MM_STATUS = 'UPDATE_HOTEL_MM_STATUS';

export const MESSENGE_SOCKET_V2 = 'MESSENGE_SOCKET_V2';
export const MESSENGE_SOCKET_V2_SUCCESS = 'MESSENGE_SOCKET_V2_SUCCESS';

export const MESSENGE_SOCKET_V2_NOT_EXIT_CONVERSTATION =
  'MESSENGE_SOCKET_V2_NOT_EXIT_CONVERSTATION';

export const MESSENGE_SOCKET_V2_NOT_EXIT_CONVERSTATION_SUCCESS =
  'MESSENGE_SOCKET_V2_NOT_EXIT_CONVERSTATION_SUCCESS';

export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAILED = 'FETCH_NOTIFICATION_FAILED';

export const MARK_READ_NOTIFICATION = 'MARK_READ_NOTIFICATION_V2';
export const MARK_READ_NOTIFICATION_SUCCESS = 'MARK_READ_NOTIFICATION_V2_SUCCESS';
export const MARK_READ_NOTIFICATION_FAILED = 'MARK_READ_NOTIFICATION_V2_FAILED';

export const MARK_READ_NOTIFICATION_SAME_HOTEL_SAME_CHANNEL =
  'MARK_READ_NOTIFICATION_SAME_HOTEL_SAME_CHANNEL_V2';

export const MARK_READ_NOTIFICATION_SAME_HOTEL_DIFF_CHANNEL =
  'MARK_READ_NOTIFICATION_SAME_HOTEL_DIFF_CHANNEL_V2';

export const MARK_READ_NOTIFICATION_DIFF_HOTEL = 'MARK_READ_NOTIFICATION_SAME_HOTEL_DIFF_HOTEL';

export const AGENT_NOTIFICATIONS_SOCKET = 'AGENT_NOTIFICATIONS_V2_SOCKET';
export const AGENT_NOTIFICATIONS_SOCKET_SUCCESS = 'AGENT_NOTIFICATIONS_V2_SOCKET_SUCCESS';

export const TAKE_OVER_SOCKET = 'TAKE_OVER_SOCKET_V2';
export const TAKE_OVER_SOCKET_SUCCESS = 'TAKE_OVER_SOCKET_SUCCESS_V2';

export const FILTER_CONVERSTATION = 'FILTER_CONVERSTATION_V2';
export const FILTER_CONVERSTATION_SUCCESS = 'FILTER_CONVERSTATION_SUCCESS_V2';
export const FILTER_CONVERSTATION_FAILED = 'FILTER_CONVERSTATION_FAILED_V2';

export const CLEAR_CONVERSATIONS = 'CLEAR_CONVERSATIONS';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE_V2';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_V2_SUCCESS';
export const CHANGE_LANGUAGE_FAILED = 'CHANGE_LANGUAGE_V2_FAILED';

export const DEFAULT_LANGUAGE = 'DEFAULT_LANGUAGE';

export const LANGUAGE_SEND = 'LANGUAGE_SEND';
export const CHANGE_LANGUAGE_TO_SEND = 'CHANGE_LANGUAGE_TO_SEND_V2';

export const FETCH_MESSENGE_TRANS = 'FETCH_MESSENGE_TRANS';
export const FETCH_MESSENGE_TRANS_SUCCESS = 'FETCH_MESSENGE_TRANS_SUCCESS';
export const FETCH_MESSENGE_TRANS_FAILED = 'FETCH_MESSENGE_TRANS_FAILED';

import * as types from '../actionTypes/labels';

export const fetchLabels = () => ({
  type: types.FETCH_LABELS,
});

export const fetchLabelsSuccess = data => ({
  type: types.FETCH_LABELS_SUCCESS,
  payload: {
    data,
  },
});

export const fetchLabelsFailed = error => ({
  type: types.FETCH_LABELS_FALED,
  payload: {
    error,
  },
});

export const addLabel = query => ({
  type: types.ADD_LABEL,
  payload: {
    query,
  },
});

export const addLabelSuccess = data => ({
  type: types.ADD_LABEL_SUCCESS,
  payload: {
    data,
  },
});

export const addLabelFailed = error => ({
  type: types.ADD_LABEL_FAILED,
  payload: {
    error,
  },
});

export const deleteLabel = query => ({
  type: types.DELETE_LABEL,
  payload: {
    query,
  },
});

export const deleteLabelSuccess = data => ({
  type: types.DELETE_LABEL_SUCCESS,
  payload: {
    data,
  },
});

export const deleteLabelFailed = error => ({
  type: types.DELETE_LABEL_FAILED,
  payload: {
    error,
  },
});

export const addLabelCustomer = query => ({
  type: types.ADD_LABEL_CUSTOMER,
  payload: {
    query,
  },
});

export const addLabelCustomerSuccess = data => ({
  type: types.ADD_LABEL_CUSTOMER_SUCCESS,
  payload: {
    data,
  },
});

export const addLabelCustomerFailed = error => ({
  type: types.ADD_LABEL_CUSTOMER_FAILED,
  payload: {
    error,
  },
});

export const deleteLabelCustomer = query => ({
  type: types.DELETE_LABEL_CUSTOMER,
  payload: {
    query,
  },
});

export const deleteLabelCustomerSuccess = data => ({
  type: types.DELETE_LABEL_CUSTOMER_SUCCESS,
  payload: {
    data,
  },
});

export const deleteLabelCustomerFailed = error => ({
  type: types.DELETE_LABEL_CUSTOMER_FAILED,
  payload: {
    error,
  },
});

export const fetchNotes = query => ({
  type: types.FETCH_NOTES,
  payload: {
    query,
  },
});

export const fetchNotesSuccess = data => ({
  type: types.FETCH_NOTES_SUCCESS,
  payload: {
    data,
  },
});

export const fetchNotesFailed = error => ({
  type: types.FETCH_NOTES_FAILED,
  payload: {
    error,
  },
});

export const addNote = query => ({
  type: types.ADD_NOTE,
  payload: {
    query,
  },
});

export const addNoteSuccess = data => ({
  type: types.ADD_NOTE_SUCCESS,
  payload: {
    data,
  },
});

export const addNoteFailed = error => ({
  type: types.ADD_NOTE_FAILED,
  payload: {
    error,
  },
});

export const editNote = query => ({
  type: types.EDIT_NOTE,
  payload: {
    query,
  },
});

export const editNoteSuccess = data => ({
  type: types.EDIT_NOTE_SUCCESS,
  payload: {
    data,
  },
});

export const editNoteFailed = error => ({
  type: types.EDIT_NOTE_FAILED,
  payload: {
    error,
  },
});

export const deleteNote = id => ({
  type: types.DELETE_NOTE,
  payload: {
    id,
  },
});

export const deleteNoteSuccess = data => ({
  type: types.DELETE_NOTE_SUCCESS,
  payload: {
    data,
  },
});

export const deleteNoteFailed = error => ({
  type: types.DELETE_NOTE_FAILED,
  payload: {
    error,
  },
});

export const clearDataLabelCustomer = () => ({
  type: types.CLEAR_DATA_LABEL_CUSTOMER,
});

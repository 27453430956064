import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

// Vendor dependencies

// Application Styles
import './assets/scss/dashforge.scss';
import './assets/css/dashforge.demo.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/scss/icon.scss';
import './assets/scss/new-authen.scss';
import './assets/scss/custome.scss';
import './assets/scss/pages/chat.scss';
import './assets/scss/chat.scss';
import './assets/scss/chatv2.scss';
import './assets/scss/chatv3.scss';
import './assets/scss/availibility.scss';
import './assets/scss/promotion.scss';
import './assets/scss/sidedrawer.scss';
import './assets/scss/botman.scss';
import './assets/scss/dashboard.scss';
import './assets/scss/yearly-table.scss';
import './assets/scss/v3.scss';

ReactDOM.render(<App />, document.getElementById('app'));

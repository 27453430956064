export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILED = 'FETCH_ORDERS_FAILED';

export const FETCH_ORDERS_STATUS = 'FETCH_ORDERS_STATUS';
export const FETCH_ORDERS_STATUS_SUCCESS = 'FETCH_ORDERS_STATUS_SUCCESS';
export const FETCH_ORDERS_STATUS_FAILED = 'FETCH_ORDERS_STATUS_FAILED';

export const UPDATE_ORDER = 'UPDATE_ORDER';

export const FETCH_ORDER_DETAIL = 'FETCH_ORDER_DETAIL';
export const FETCH_ORDER_DETAIL_SUCCESS = 'FETCH_ORDER_DETAIL_SUCCESS';
export const FETCH_ORDER_DETAIL_FAILED = 'FETCH_ORDER_DETAIL_FAILED';

export const CLEAR_ORDER_DETAIL = 'CLEAR_ORDER_DETAIL';

export const MARK_READ_ORDERS = 'MARK_READ_ORDERS';

export const NEW_ORDER_SOCKET = 'NEW_ORDER_SOCKET';

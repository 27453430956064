import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropsType from 'prop-types';
import GlobalLoading from '@Components/GlobalLoading';
import ErrorBoundary from '@Components/ErrorBoundary';
import Header from '@ComponentsV2/Layout/components/Header';
import SideBar from '@ComponentsV2/Layout/components/Sidebar';

function InStayServiceLayout(props) {
  const { children, type } = props;
  const isExpandMenu = useSelector(state => state.ui.isExpandMenu);

  return (
    <ErrorBoundary name="Instay Service" url="/">
      <Header type={type} />
      <div className={`dashboard-main dashboard-cus ${isExpandMenu ? '' : 'mini-menu'}`}>
        <SideBar type={type} />
        <div className="pd-0 pd-t-20 main-body">
          <div className="">{children}</div>
        </div>
        <GlobalLoading />
      </div>
    </ErrorBoundary>
  );
}

InStayServiceLayout.propTypes = {
  children: PropsType.object,
  type: PropsType.string,
};

export default memo(InStayServiceLayout);

import axiosService from '../lib/axiosService';

const url = `${process.env.API_ENDPOINT}`;

export const getLabels = () => {
  return axiosService.get(`${url}/tags`);
};

export const addLabel = query => {
  return axiosService.post(`${url}/tags/create`, query.query);
};

export const deleteLabel = query => {
  return axiosService.post(`${url}/tags/delete`, query.query);
};

export const addLabelCustomer = query => {
  return axiosService.post(`${url}/tags/add-tag`, query.query);
};

export const deleteLabelCustomer = query => {
  return axiosService.post(`${url}/tags/delete-customer-labels`, query.query);
};

export const fetchNotes = query => {
  const { hotelId, customerId } = query.query;
  return axiosService.get(`${url}/customer/notes/list/${hotelId}/${customerId}`);
};

export const addNote = query => {
  return axiosService.post(`${url}/customer/notes/add`, query.query);
};

export const editNote = query => {
  return axiosService.post(`${url}/customer/notes/edit`, query.query);
};

export const deleteNote = query => {
  const { id } = query;
  return axiosService.post(`${url}/customer/notes/delete/${id}`);
};

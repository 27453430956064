import * as types from '../actionTypes/chatv2';
import { toastError } from '../../lib/toastHelper';

const initialState = {
  channel: 'facebook',
  conversations: null,
  conversationShow: [],
  memberActive: null,
  messenge: null,
  messengeShow: [],
  loadingMessenge: false,
  hotels: [],
  hotel: null,
  scrollToBottom: true,
  channelUnred: [],
  notifications: [],
  ignoreId: [],
  markRead: false,
  translateTo: '',
  messengeTranShow: [],
  defaultLanguage: 'vi',
  translateToSend: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CONVERSATION:
    case types.FETCH_HOTELS_FROM_MM:
    case types.CHANGE_LANGUAGE:
      return { ...state };
    case types.FETCH_MESSENGE_V2:
      return {
        ...state,
        loadingMessenge: true,
      };
    case types.FETCH_CONVERSATION_SUCCESS: {
      const { data } = action.payload;
      const converData = data.data;
      const { conversationShow } = state;
      const newConversationShow = [...conversationShow, ...converData];
      return {
        ...state,
        conversations: data,
        conversationShow: newConversationShow,
      };
    }
    case types.FETCH_CONVERSATION_FAILED:
    case types.FETCH_HOTELS_FROM_MM_FAILED:
    case types.FETCH_NOTIFICATION_FAILED:
    case types.MARK_READ_NOTIFICATION_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
      };
    }
    case types.SET_MEMBER_ACTIVE_V2: {
      const { data } = action.payload;
      return {
        ...state,
        messenge: null,
        messengeShow: [],
        memberActive: data,
      };
    }
    case types.FETCH_MESSENGE_SUCCESS_V2: {
      const messenge = action.payload.data;
      const { messengeShow } = state;
      const newMessengeShow = [...messenge.data.reverse(), ...messengeShow];
      return {
        ...state,
        messenge,
        messengeShow: newMessengeShow,
        loadingMessenge: false,
      };
    }
    case types.FETCH_MESSENGE_FAILED_V2: {
      return {
        ...state,
        loadingMessenge: false,
      };
    }
    case types.ADD_NEW_MESS: {
      const mess = action.payload.data;
      const { messengeShow } = state;
      return {
        ...state,
        messengeShow: [...messengeShow, mess],
        scrollToBottom: !state.scrollToBottom,
      };
    }
    case types.ADD_NEW_MESS_FAILED: {
      const { id } = action.payload;
      const { messengeShow } = state;
      const cloneMessengeShow = JSON.parse(JSON.stringify(messengeShow));
      const findMess = cloneMessengeShow.find(mess => mess.id === id);
      if (findMess) {
        findMess.error = true;
      }
      return {
        ...state,
        messengeShow: [...cloneMessengeShow],
        scrollToBottom: !state.scrollToBottom,
      };
    }
    case types.FETCH_HOTELS_FROM_MM_SUCCESS: {
      const hotels = action.payload.data;
      return {
        ...state,
        hotels: hotels.data,
      };
    }
    case types.SET_HOTEL_MM_ACTIVE: {
      const hotel = action.payload.data;
      return {
        ...state,
        hotel,
        ignoreId: [],
        // messengeShow: [],
        // messenge: null,
        // memberActive: null,
      };
    }
    case types.CHANGE_CHANNEL: {
      const channel = action.payload.data;
      return {
        ...state,
        channel,
        memberActive: null,
        messengeShow: [],
        messenge: null,
        ignoreId: [],
      };
    }
    case types.UPDATE_CONVERSATION_STATUS: {
      const { data } = action.payload;
      const { conversationShow } = state;
      const findConversation = conversationShow.find(item => item.id === data.id);
      let cloneConvertionShow = conversationShow;
      if (findConversation) {
        findConversation.has_unread_message = data.has_unread_message === 1;
        findConversation.updated_at = data.updated_at;
        if (data.has_unread_message) {
          cloneConvertionShow = conversationShow.filter(item => item.id !== data.id);
          cloneConvertionShow.unshift(findConversation);
        }
      }
      return {
        ...state,
        conversationShow: [...cloneConvertionShow],
      };
    }
    case types.UPDATE_HOTEL_MM_STATUS: {
      const { data } = action.payload;
      const { hotels } = state;
      const findHotelMM = hotels.find(item => item.id === data.id);
      if (findHotelMM) {
        findHotelMM.unread_conversation_count = data.unread_conversation_count;
        findHotelMM.unread_channel = data.unread_channel;
      }
      return {
        ...state,
        hotels: [...hotels],
      };
    }
    case types.MESSENGE_SOCKET_V2_SUCCESS: {
      const { data: socket } = action.payload;
      const { memberActive, messengeShow } = state;
      if (memberActive && socket.conversation_id === memberActive.id) {
        let newMessengeShow = [];
        const findIndexMessExit = messengeShow.findIndex(
          mess => mess.message_raw.text === socket.message_raw.text && mess.replica,
        );
        if (findIndexMessExit >= 0) {
          messengeShow[findIndexMessExit] = socket;
          const removeDup = JSON.parse(JSON.stringify(messengeShow));
          newMessengeShow = removeDup
            .filter(item => !item.replica)
            .sort((a, b) => a.created_at > b.created_at);
        } else {
          newMessengeShow = [...messengeShow, socket];
        }
        return {
          ...state,
          messengeShow: [...newMessengeShow],
        };
      }
      return {
        ...state,
      };
    }
    case types.FETCH_NOTIFICATION_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        notifications: data.data,
      };
    }
    case types.MARK_READ_NOTIFICATION_SUCCESS: {
      const {
        memberActive,
        messenge,
        messengeShow,
        conversationShow,
        ignoreId,
      } = action.payload.data;
      const { markRead } = state;
      return {
        ...state,
        memberActive: { ...memberActive },
        messenge,
        messengeShow,
        markRead: !markRead,
        conversationShow,
        ignoreId,
      };
    }
    case types.AGENT_NOTIFICATIONS_SOCKET_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        notifications: data,
      };
    }
    case types.MESSENGE_SOCKET_V2_NOT_EXIT_CONVERSTATION_SUCCESS: {
      const { data } = action.payload;
      const { conversationShow, ignoreId } = state;

      return {
        ...state,
        conversationShow: [data, ...conversationShow],
        ignoreId: [...ignoreId, data.id],
      };
    }
    case types.TAKE_OVER_SOCKET_SUCCESS: {
      const { data } = action.payload;
      const { memberActive, conversationShow, isEmptyList } = data;
      const stateChange = {
        ...state,
        memberActive: JSON.parse(JSON.stringify(memberActive)),
        conversationShow: JSON.parse(JSON.stringify(conversationShow)),
        messengeShow: [],
        messenge: null,
      };
      if (isEmptyList) {
        stateChange.messengeShow = [];
        stateChange.messenge = null;
      }
      return stateChange;
    }
    case types.CLEAR_CONVERSATIONS: {
      return {
        ...state,
        conversationShow: [],
        conversations: null,
      };
    }
    case types.CHANGE_LANGUAGE_SUCCESS: {
      const { translations, lang } = action.payload.data;
      return {
        ...state,
        translateTo: lang,
        messengeTranShow: translations,
      };
    }
    case types.FETCH_MESSENGE_TRANS_SUCCESS: {
      const translations = action.payload.data;
      const { messengeTranShow } = state;
      const updateMess = [...translations.reverse(), ...messengeTranShow];
      return {
        ...state,
        messengeTranShow: updateMess,
      };
    }
    case types.CHANGE_LANGUAGE_TO_SEND: {
      const { lang } = action.payload;
      return {
        ...state,
        translateToSend: lang,
      };
    }
    case types.DEFAULT_LANGUAGE: {
      return {
        ...state,
        translateTo: '',
      };
    }
    default:
      return state;
  }
};

export default reducer;

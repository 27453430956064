import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

function PaymentSuccess() {
  useEffect(() => {
    ((d, s, id) => {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.com/en_US/messenger.Extensions.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'Messenger');
  }, []);

  const returnMessenger = () => {
    window.extAsyncInit = () => {
      // the Messenger Extensions JS SDK is done loading
      MessengerExtensions.requestCloseBrowser();
      window.close();
    };
  };
  const { t } = useTranslation('payment');
  return (
    <>
      <Helmet>
        <title>{t('paymentSuccess')}</title>
        <meta name="og:title" content={t('paymentSuccess')} />
      </Helmet>
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="payment-success text-center mg-x-20">
          <img
            style={{ maxWidth: '100px' }}
            className="w-100h"
            src="/images/webview/payment/payment-success.png"
            alt="payment-success"
          />
          <h3 style={{ color: '#00b69d' }} className="mg-y-20">
            {t('paymentSuccess')}
          </h3>
          <p>{t('thankForPayment')}</p>
          <p>{t('paymentCheck')}</p>
        </div>
      </div>
    </>
  );
}

export default PaymentSuccess;

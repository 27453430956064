import { call, put, takeLatest, select } from 'redux-saga/effects';
import { fetchOrderDetail, fetchOrders, fetchOrdersStatus } from '../../apis/instayOrder';
import {
  fetchOrderDetailFailed,
  fetchOrderDetailSuccess,
  fetchOrdersStatusSuccess,
  fetchOrdersSuccess,
} from '../actions/instayOrder';
import { STATUS_CODE } from '../../constants';
import * as types from '../actionTypes/instayOrder';
import { toastFailed } from '../../lib/toastHelper';

function* processFetchOrders({ payload }) {
  try {
    const { query } = payload;
    const resp = yield call(fetchOrders, query);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchOrdersSuccess(data));
    }
  } catch (err) {
    toastFailed(err);
  }
}

function* processFetchOrdersStatus({ payload }) {
  try {
    const { query } = payload;
    const resp = yield call(fetchOrdersStatus, query);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchOrdersStatusSuccess(data));
    }
  } catch (error) {
    toastFailed(error);
  }
}

function* processFetchOrderDetail({ payload }) {
  try {
    const { query } = payload;
    const resp = yield call(fetchOrderDetail, query);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchOrderDetailSuccess(data.data));
    }
  } catch (err) {
    toastFailed(err);
    yield put(fetchOrderDetailFailed(err));
  }
}

function* instayOrderSaga() {
  yield takeLatest(types.FETCH_ORDERS, processFetchOrders);
  yield takeLatest(types.FETCH_ORDERS_STATUS, processFetchOrdersStatus);
  yield takeLatest(types.FETCH_ORDER_DETAIL, processFetchOrderDetail);
}

export default instayOrderSaga;

import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import WebviewLayout from '../Layout/WebviewLayout';

function WebviewRoutes(props) {
  const { component: YourComponent, ...remainProps } = props;
  return (
    <Route
      {...remainProps}
      render={routeProps => {
        return (
          <WebviewLayout>
            <YourComponent {...routeProps} />
          </WebviewLayout>
        );
      }}
    />
  );
}

WebviewRoutes.propTypes = {
  component: PropTypes.object,
};

export default WebviewRoutes;

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const MINI_MENU = 'MINI_MENU';
export const NORMAL_MENU = 'NORMAL_MENU';

export const TOGGLE_MENU = 'TOGGLE_MENU';

export const SET_SIDEBAR = 'SET_SIDEBAR';

export const SET_SIDEBAR_ACTIVE = 'SET_SIDEBAR_ACTIVE';

export const TOGGLE_CHILD_SIDEBAR = 'TOGGLE_CHILD_SIDEBAR';

export const SET_CHILD_SIDEBAR_ACTIVE = 'SET_CHILD_SIDEBAR_ACTIVE';

export const CLEAR_CHILD_SIDEBAR_ACTIVE = 'CLEAR_CHILD_SIDEBAR_ACTIVE';

export const CLEAR_SIDEBAR = 'CLEAR_SIDEBAR';

import { push } from 'connected-react-router';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as types from '../actionTypes/auth';
import {
  loginSuccess,
  loginFailed,
  getInfoSuccess,
  getInfoFailed,
  setHotelActiveSuccess,
  notifyHotelSuccess,
  updateOnboardHotelSuccess,
  updateCurrencySettingSuccess,
} from '../actions/auth';
import { login, getInfo } from '../../apis/auth';
import { STATUS_CODE } from '../../constants';
import axiosService from '../../lib/axiosService';

function* processLogin({ payload }) {
  try {
    const { email, password } = payload;
    const resp = yield call(login, {
      email,
      password,
    });
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(loginSuccess(data));
      const { token } = data;
      localStorage.setItem(process.env.TOKEN, token);
      axiosService.setHeader('Authorization', `Bearer ${token}`);
    }
  } catch (error) {
    const errorString = 'email or password incorrect';
    yield put(loginFailed(errorString));
  }
}

function* processGetInfo({ payload }) {
  try {
    const resp = yield call(getInfo);
    const { hotelSlug } = payload;
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      const hotels = data.hotels;
      yield put(getInfoSuccess(data));
      if (hotels.length === 0) {
        yield put(push('/onboard'));
      }
      if (hotels.length > 0 && hotelSlug) {
        const hotel = hotels.find(hotel => hotel.slug === hotelSlug);
        if (hotel) {
          yield put(setHotelActiveSuccess(hotel));
        } else {
          yield put(push('/page-not-found'));
        }
      }
      if (hotels.length > 0 && !hotelSlug) {
        const hotel = hotels[0];
        yield put(setHotelActiveSuccess(hotel));
        const isAdmin = hotel.userRole.is_admin;
        if (isAdmin === 0) {
          yield put(push(`/${hotel.slug}/order-history/vouchers`));
        } else {
          yield put(push(`/${hotel.slug}/dashboard`));
        }
      }
    }
  } catch (error) {
    const { response } = error;
    yield put(getInfoFailed(response));
  }
}

function* processSetActiveHotel({ payload }) {
  try {
    const { hotelSlug } = payload;
    const state = yield select();
    const { hotels } = state.auth;
    let hotel;
    if (hotelSlug) {
      const hotel = hotels.find(hotel => hotel.slug === hotelSlug);
      if (hotel) {
        // if (hotel.languages.length > 1) {
        //   hotel.languages.sort();
        //   hotel.languages.reverse();
        // }
        yield put(setHotelActiveSuccess(hotel));
      } else {
        yield put(push('/page-not-found'));
      }
    } else {
      hotel = hotels[0];
      yield put(setHotelActiveSuccess(hotel));
      yield put(push(`/${hotel.slug}/dashboard`));
    }
  } catch (error) {}
}

function* processNotifyHotel({ payload }) {
  try {
    const dataMess = payload.data;
    const state = yield select();
    const { hotels } = state.auth;
    hotels.map(hotel => {
      if (hotel.id === dataMess.hotel_id) {
        if (hotel.mess) {
          hotel.mess = hotel.mess++;
        } else {
          hotel.mess = 1;
        }
      }
    });
    yield put(notifyHotelSuccess(hotels));
  } catch (error) {}
}

function* processUpdateOnboardHotel({ payload }) {
  try {
    const state = yield select();
    const { hotels } = state.auth;
    const { data } = payload;
    const updatedHotels = [...hotels, ...[data]];
    yield put(updateOnboardHotelSuccess(updatedHotels));
  } catch (error) {}
}

function* processUpdateCurrencySetting({ payload }) {
  try {
    const state = yield select();
    const { hotel } = state.auth;
    const { data } = payload;
    const updatedHotel = { ...hotel, languages: data.languages, locale: data.locale };
    yield put(updateCurrencySettingSuccess(updatedHotel));
  } catch (error) {}
}

function* authSaga() {
  yield takeLatest(types.LOGIN, processLogin);
  yield takeLatest(types.GET_INFO, processGetInfo);
  yield takeLatest(types.SET_HOTEL_ACTIVE, processSetActiveHotel);
  yield takeLatest(types.NOTIFY_HOTEL, processNotifyHotel);
  yield takeLatest(types.UPDATE_ONBOARD_HOTEL, processUpdateOnboardHotel);
  yield takeLatest(types.UPDATE_CURRENCY_SETTING, processUpdateCurrencySetting);
}

export default authSaga;

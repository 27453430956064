import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropsType from 'prop-types';
import Modal from 'react-modal';
import Header from '@ComponentsV2/Layout/components/Header';
import { DASHBOARD_TYPE } from '../../../constants';
import GlobalLoading from '../../GlobalLoading';
import ErrorBoundary from '../../ErrorBoundary';

Modal.setAppElement('#app');

function LiveChatLayout(props) {
  const { children } = props;
  return (
    <>
      <ErrorBoundary name="Live Chat" url={window.location.href}>
        <Header type={DASHBOARD_TYPE.LIVE_CHAT} />
        {children}
        <GlobalLoading />
      </ErrorBoundary>
    </>
  );
}

LiveChatLayout.propTypes = {
  children: PropsType.object,
};

export default memo(LiveChatLayout);

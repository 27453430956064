/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable prettier/prettier */
import shortid from 'shortid';
import { lazy } from 'react';

export const liveChatConstant = [
  {
    id: shortid.generate(),
    path: '/chat',
    component: lazy(() => import('../../view/ChatPageV3')),
    name: 'Live Chat',
    exact: true,
  },
];

export const publicConstant = [
  {
    id: shortid.generate(),
    path: '/login',
    component: lazy(() => import('../../view/Authen/Login')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/signup',
    component: lazy(() => import('../../view/Authen/Signup')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/onboard',
    component: lazy(() => import('../../view/Authen/Onboard')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/password-reset',
    component: lazy(() => import('../../view/Authen/ResetPassword')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/password-reset/:token',
    component: lazy(() => import('../../view/Authen/ResetPassword')),
    exact: true,
  },
];

export const webviewConstant = [
  {
    id: shortid.generate(),
    path: '/webview/voucher',
    component: lazy(() => import('../../view/Webview/Voucher')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/booking-flexible',
    component: lazy(() => import('../../view/Webview/BookingFlexible')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/payment/:type/:hashedId',
    component: lazy(() => import('../../view/Webview/Payment')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/payment/cancelCallback',
    component: lazy(() => import('../../view/Webview/Payment/NganLuongReturn/CancelCallBack')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/payment/resultCallBack',
    component: lazy(() => import('../../view/Webview/Payment/NganLuongReturn/ResultCallBack')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/payment/banktranfer-success',
    component: lazy(() => import('../../view/Webview/Payment/Result/BankTransferSuccess')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/voucher/redeemSuccess',
    component: lazy(() => import('../../view/Webview/Voucher/RedeemSucess')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/restaurant',
    component: lazy(() => import('../../view/Webview/Restaurant')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/room-check-in',
    component: lazy(() => import('../../view/Webview/RoomCheckIn')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/in-stay-service',
    component: lazy(() => import('../../view/Webview/InStayService')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/in-stay-service/restaurant',
    component: lazy(() => import('../../view/Webview/InStayService/Restaurant')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/in-stay-service/:category',
    component: lazy(() => import('../../view/Webview/InStayService/InRoom')),
    exact: true,
  },
  {
    id: shortid.generate(),
    path: '/webview/booking-cancel',
    component: lazy(() => import('../../view/Webview/BookingCancel')),
    exact: true,
  },
];

export const inStayServiceConstant = [
  {
    id: shortid.generate(),
    name: 'Dashboard',
    path: '/in-stay-service/',
    component: lazy(() => import('../../view/InStayService/Dashboard')),
    icon: '/images/v3/icon-dashboard/dashboard.svg',
    exact: true,
  },
  {
    id: shortid.generate(),
    name: 'Check-in Manager',
    path: '/in-stay-service/guest-orders/check-in-manager',
    component: lazy(() => import('../../view/InStayService/CheckInManager')),
    icon: '/images/v3/icon-dashboard/check-in-manager.svg',
    exact: true,
  },
  {
    id: shortid.generate(),
    name: 'Order Manager',
    path: '/in-stay-service/guest-orders/order-manager',
    component: lazy(() => import('../../view/InStayService/OrderManager')),
    icon: '/images/v3/icon-dashboard/order.svg',
    exact: true,
  },
  {
    id: shortid.generate(),
    name: 'In-room Dining',
    icon: '/images/v3/icon-dashboard/in-room-dining.svg',
    exact: true,
    child: [
      {
        id: shortid.generate(),
        path: '/in-stay-service/in-room-dining/category-manager',
        component: lazy(() => import('../../view/InStayService/CategoryManager/ItemCategory')),
        name: 'Category Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/in-room-dining',
        component: lazy(() => import('../../view/InStayService/CategoryManager')),
        name: 'Item Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/in-room-dining/addNew',
        component: lazy(() => import('../../view/InStayService/CategoryManager/Modify')),
        name: 'Add New Item',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/in-room-dining/modify/:id',
        component: lazy(() => import('../../view/InStayService/CategoryManager/Modify')),
        name: 'Edit Item',
        hidden: true,
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Restaurant',
    icon: '/images/v3/icon-dashboard/restaurant.svg',
    exact: true,
    child: [
      {
        id: shortid.generate(),
        path: '/in-stay-service/restaurant',
        component: lazy(() => import('../../view/InStayService/Restaurant')),
        name: 'Restaurant Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/restaurant/addNew',
        component: lazy(() => import('../../view/InStayService/Restaurant/Modify')),
        name: 'Add New Restaurant',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/restaurant/modify/:id',
        component: lazy(() => import('../../view/InStayService/Restaurant/Modify')),
        name: 'Edit Restaurant',
        hidden: true,
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Spa & Wellness',
    icon: '/images/v3/icon-dashboard/spa-wellness.svg',
    exact: true,
    child: [
      {
        id: shortid.generate(),
        path: '/in-stay-service/spa-wellness/set-up',
        component: lazy(() => import('../../view/InStayService/SpaAndWellness/Modify')),
        name: 'Setup Spa & Wellness',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/spa-wellness/category-manager',
        component: lazy(() => import('../../view/InStayService/CategoryManager/ItemCategory')),
        name: 'Category Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/spa-wellness',
        component: lazy(() => import('../../view/InStayService/CategoryManager')),
        name: 'Item Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/spa-wellness/addNew',
        component: lazy(() => import('../../view/InStayService/CategoryManager/Modify')),
        name: 'Add New Item',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/spa-wellness/modify/:id',
        component: lazy(() => import('../../view/InStayService/CategoryManager/Modify')),
        name: 'Edit Item',
        hidden: true,
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Tours & Attractions',
    icon: '/images/v3/icon-dashboard/tour.svg',
    exact: true,
    child: [
      {
        id: shortid.generate(),
        path: '/in-stay-service/tours-attractions/category-manager',
        component: lazy(() => import('../../view/InStayService/CategoryManager/ItemCategory')),
        name: 'Category Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/tours-attractions',
        component: lazy(() => import('../../view/InStayService/CategoryManager')),
        name: 'Item Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/tours-attractions/addNew',
        component: lazy(() => import('../../view/InStayService/CategoryManager/Modify')),
        name: 'Add New Item',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/tours-attractions/modify/:id',
        component: lazy(() => import('../../view/InStayService/CategoryManager/Modify')),
        name: 'Edit Item',
        hidden: true,
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Transportation',
    icon: '/images/v3/icon-dashboard/transportation.svg',
    exact: true,
    child: [
      {
        id: shortid.generate(),
        path: '/in-stay-service/transportation/category-manager',
        component: lazy(() => import('../../view/InStayService/CategoryManager/ItemCategory')),
        name: 'Category Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/transportation',
        component: lazy(() => import('../../view/InStayService/CategoryManager')),
        name: 'Item Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/transportation/addNew',
        component: lazy(() => import('../../view/InStayService/CategoryManager/Modify')),
        name: 'Add New Item',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/transportation/modify/:id',
        component: lazy(() => import('../../view/InStayService/CategoryManager/Modify')),
        name: 'Edit Item',
        hidden: true,
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Laundry',
    icon: '/images/v3/icon-dashboard/laundry.svg',
    exact: true,
    child: [
      {
        id: shortid.generate(),
        path: '/in-stay-service/laundry/category-manager',
        component: lazy(() => import('../../view/InStayService/CategoryManager/ItemCategory')),
        name: 'Category Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/laundry',
        component: lazy(() => import('../../view/InStayService/CategoryManager')),
        name: 'Item Manager',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/laundry/addNew',
        component: lazy(() => import('../../view/InStayService/CategoryManager/Modify')),
        name: 'Add New Item',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/in-stay-service/laundry/modify/:id',
        component: lazy(() => import('../../view/InStayService/CategoryManager/Modify')),
        name: 'Edit Item',
        hidden: true,
        exact: true,
      },
    ],
  },
];

export const dashboardConstant = [
  {
    id: shortid.generate(),
    name: 'Dashboard',
    icon: '/images/dashboard/home.svg',
    path: '/dashboard',
    component: lazy(() => import('../../view/Dashboard')),
    exact: true,
  },
  {
    id: shortid.generate(),
    name: 'Hotel Info',
    icon: '/images/dashboard/hotel.svg',
    child: [
      {
        id: shortid.generate(),
        path: '/hotel/overview',
        component: lazy(() => import('../../view/Dashboard/HotelInfo/Overview')),
        name: 'Overview',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/hotel/facilities',
        component: lazy(() => import('../../view/Dashboard/HotelInfo/Facilities')),
        name: 'Facilities',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/hotel/facilities/modify/:id',
        component: lazy(() => import('../../view/Dashboard/HotelInfo/Facilities/Modify')),
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/hotel/facilities/addNew',
        component: lazy(() => import('../../view/Dashboard/HotelInfo/Facilities/Modify')),
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/hotel/amenities',
        component: lazy(() => import('../../view/Dashboard/HotelInfo/HotelAmenities')),
        name: 'Amenities',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/hotel/policies',
        component: lazy(() => import('../../view/Dashboard/HotelInfo/Policies')),
        name: 'Policies',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/hotel/faqs',
        component: lazy(() => import('../../view/Dashboard/HotelInfo/FAQs')),
        name: 'FAQs',
        exact: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Room & Rate',
    icon: '/images/dashboard/rate.svg',
    child: [
      {
        id: shortid.generate(),
        path: '/room-rate/room-types',
        name: 'Room Types',
        component: lazy(() => import('../../view/Dashboard/RoomRate/Rooms')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/room-rate/room-types/addNew',
        component: lazy(() => import('../../view/Dashboard/RoomRate/Rooms/AddEditRooms')),
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/room-rate/room-types/modify/:id',
        component: lazy(() => import('../../view/Dashboard/RoomRate/Rooms/AddEditRooms')),
        hidden: true,
      },

      {
        id: shortid.generate(),
        path: '/room-rate/rate-plans',
        component: lazy(() => import('../../view/Dashboard/RoomRate/ManageRatePlan')),
        name: 'Rateplans',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/room-rate/rates-update',
        component: lazy(() => import('../../view/Dashboard/RoomRate/RatePlanUpdate')),
        name: 'Rates Update',
        hidden: true,
      },

      {
        id: shortid.generate(),
        path: '/room-rate/promotions',
        component: lazy(() => import('../../view/Dashboard/RoomRate/PromotionUpdate')),
        name: 'Promotions',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/room-rate/promotion-update',
        component: lazy(() => import('../../view/Dashboard/RoomRate/PromotionUpdate')),
        name: 'Promotion Update',
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/room-rate/promotion/:id',
        component: lazy(() =>
          import('../../view/Dashboard/RoomRate/PromotionUpdate/PromotionDetail/PromotionDetail'),
        ),
        name: 'Promotion Update',
        hidden: true,
      },

      {
        id: shortid.generate(),
        path: '/room-rate/availability-update',
        component: lazy(() => import('../../view/Dashboard/RoomRate/AvailabilityUpdate')),
        name: 'Availability Update',
        exact: true,
      },

      {
        id: shortid.generate(),
        path: '/room-rate/extra-charges',
        component: lazy(() => import('../../view/Dashboard/RoomRate/ExtraCharges')),
        name: 'Extra charges',
      },
      {
        id: shortid.generate(),
        path: '/room-rate/surcharges',
        component: lazy(() => import('../../view/Dashboard/RoomRate/Surcharges')),
        name: 'Surcharges',
      },
      {
        id: shortid.generate(),
        path: '/room-rate/create-surcharge',
        component: lazy(() => import('../../view/Dashboard/RoomRate/Surcharges/AddEditSurcharge')),
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/room-rate/surcharge/:id',
        component: lazy(() => import('../../view/Dashboard/RoomRate/Surcharges/AddEditSurcharge')),
        hidden: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Room Voucher',
    icon: '/images/dashboard/voucher.svg',
    path: '/voucher',
    child: [
      {
        id: shortid.generate(),
        path: '/voucher/voucher-groups',
        name: 'Voucher Groups',
        exact: true,
        component: lazy(() => import('../../view/Dashboard/Voucher/VoucherGroups')),
      },
      {
        id: shortid.generate(),
        path: '/voucher/create-voucher-group',
        hidden: true,
        exact: true,
        component: lazy(() =>
          import('../../view/Dashboard/Voucher/VoucherGroups/AddEditVoucherGroup'),
        ),
      },
      {
        id: shortid.generate(),
        path: '/voucher/voucher-group/:id',
        hidden: true,
        exact: true,
        component: lazy(() =>
          import('../../view/Dashboard/Voucher/VoucherGroups/AddEditVoucherGroup'),
        ),
      },
      {
        id: shortid.generate(),
        path: '/voucher/voucher-group/:groupId/create-room-rate',
        hidden: true,
        exact: true,
        component: lazy(() => import('../../view/Dashboard/Voucher/VoucherGroups/AddEditRoomRate')),
      },
      {
        id: shortid.generate(),
        path: '/voucher/voucher-group/:groupId/room-rate/:id',
        hidden: true,
        exact: true,
        component: lazy(() => import('../../view/Dashboard/Voucher/VoucherGroups/AddEditRoomRate')),
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Order History',
    icon: '/images/dashboard/reservation.svg',
    path: '/order-history',
    component: lazy(() => import('../../view/Dashboard/OrderHistory')),
    isStaff: true,
    child: [
      {
        id: shortid.generate(),
        path: '/order-history/vouchers',
        name: 'Vouchers',
        component: lazy(() => import('../../view/Dashboard/OrderHistory/Vouchers/Vouchers')),
      },
      {
        id: shortid.generate(),
        path: '/order-history/bookings',
        name: 'Bookings',
        component: lazy(() => import('../../view/Dashboard/OrderHistory/BookingsPage/Bookings')),
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Service Voucher',
    icon: '/images/dashboard/mkt-ic.svg',
    path: '',
    child: [
      {
        id: shortid.generate(),
        path: '/restaurant/voucher-batch',
        name: 'Voucher Batch',
        component: lazy(() => import('../../view/Dashboard/Restaurant/VoucherBatch')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/restaurant/voucher-batch/addNew',
        component: lazy(() => import('../../view/Dashboard/Restaurant/VoucherBatch/Modify')),
        name: 'Add New Voucher Batch',
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/restaurant/voucher-batch/modify/:id',
        component: lazy(() => import('../../view/Dashboard/Restaurant/VoucherBatch/Modify')),
        name: 'Edit Voucher Batch',
        hidden: true,
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/restaurant/sell-voucher',
        name: 'Sell Voucher',
        component: lazy(() => import('../../view/Dashboard/Restaurant/SellVoucher')),
      },
      {
        id: shortid.generate(),
        path: '/restaurant/redeem-voucher',
        name: 'Redeem Voucher',
        component: lazy(() => import('../../view/Dashboard/Restaurant/RedeemVoucher')),
      },
      {
        id: shortid.generate(),
        path: '/restaurant/transactions',
        name: 'Transactions',
        component: lazy(() => import('../../view/Dashboard/Restaurant/Transactions')),
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Marketing',
    icon: '/images/dashboard/mkt-ic.svg',
    path: '',
    child: [
      {
        id: shortid.generate(),
        path: '/marketing/sponsored-message',
        name: 'Sponsored Message',
        component: lazy(() => import('../../view/Dashboard/Marketing/SponsorMessage')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/marketing/sponsored-message/addNew',
        component: lazy(() => import('../../view/Dashboard/Marketing/SponsorMessage/Modify')),
        hidden: true,
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/marketing/sponsored-message/modify/:id',
        component: lazy(() => import('../../view/Dashboard/Marketing/SponsorMessage/Modify')),
        hidden: true,
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/marketing/facebook-comments',
        name: 'Facebook Comments',
        component: lazy(() => import('../../view/Dashboard/Marketing/FacebookComments')),
        exact: true,
      },
      {
        id: shortid.generate(),
        path: '/marketing/facebook-comments/addNew',
        name: 'Facebook Comments',
        component: lazy(() => import('../../view/Dashboard/Marketing/FacebookComments/Modify')),
        exact: true,
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/marketing/facebook-comments/modify/:id',
        name: 'Facebook Comments',
        component: lazy(() => import('../../view/Dashboard/Marketing/FacebookComments/Modify')),
        exact: true,
        hidden: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Setting',
    icon: '/images/dashboard/setting.svg',
    path: '/setting',
    component: lazy(() => import('../../view/Dashboard/Setting')),
    child: [
      {
        id: shortid.generate(),
        path: '/setting/connect-chatbot',
        name: 'Connect Chatbot',
        exact: true,
        component: lazy(() => import('../../view/Dashboard/Setting/ConnectChatbot')),
      },
      {
        id: shortid.generate(),
        path: '/setting/connect-chatbot/:channel',
        hidden: true,
        exact: true,
        component: lazy(() => import('../../view/Dashboard/Setting/ConnectChatbot')),
      },
      {
        id: shortid.generate(),
        path: '/setting/locale',
        name: 'Languages & Currency',
        component: lazy(() => import('../../view/Dashboard/Setting/Locale')),
      },
      {
        id: shortid.generate(),
        path: '/setting/user-manager',
        name: 'User Manager',
        component: lazy(() => import('../../view/Dashboard/Setting/UserManager')),
      },
    ],
  },
];

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import InStayServiceLayout from '@ComponentsV2/Layout/InStayServiceLayout';
import axiosService from '@Lib/axiosService';

function InStayServiceRoutes(props) {
  const { component: YourComponent, ...remainProps } = props;
  const { type } = remainProps;
  const token = localStorage.getItem(process.env.TOKEN);
  if (token) {
    axiosService.setHeader('Authorization', `Bearer ${token}`);
  }
  return (
    <Route
      {...remainProps}
      render={routeProps => {
        return token ? (
          <InStayServiceLayout type={type}>
            <YourComponent {...routeProps} />
          </InStayServiceLayout>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
}

InStayServiceRoutes.propTypes = {
  component: PropTypes.object,
  type: PropTypes.string,
};

export default InStayServiceRoutes;

import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as uiActions from '@Store/actions/ui';

export default function ItemSidebarHasSub({ item }) {
  const hotel = useSelector(state => state.auth.hotel);
  const isExpandMenu = useSelector(state => state.ui.isExpandMenu);
  const chidActive = useSelector(state => state.ui.chidActive);
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    const childMenu = item.child.find(childRoute => `/:slug${childRoute.path}` === routeMatch.path);
    if (childMenu) {
      dispatch(uiActions.setSidebarActive(item.id));
      dispatch(uiActions.setChildSidebarActive(childMenu));
    }
  }, []);

  const handleClickSideBar = () => {
    if (item.isActive) {
      dispatch(uiActions.toggleChildSidebar());
    } else {
      dispatch(uiActions.setSidebarActive(item.id));
    }
  };

  const handleClickChild = childSidebar => {
    dispatch(uiActions.setChildSidebarActive(childSidebar));
    history.push(`/${hotel.slug}${childSidebar.path}`);
  };

  return (
    <li className={`${item.isActive ? 'active-item' : ''}`}>
      <button className="btn nav-link w-100" type="button" onClick={() => handleClickSideBar()}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img src={item.icon} className="mg-r-10" alt="icon" />
            <span className="tx-16 tx-medium sidebar-menu-name">{item.name}</span>
          </div>
          {!item.isOpen && <Icon.ChevronRight />}
          {item.isOpen && <Icon.ChevronDown color="#0a7aff" />}
        </div>
      </button>
      {item.isOpen &&
        item.child &&
        item.child.length > 0 &&
        item.child.map(child => {
          if (!child.hidden) {
            return (
              <div
                className={`child-menu-item ${child.id === chidActive?.id ? 'active' : ''}`}
                key={child.id}
              >
                <div
                  className="tx-medium d-flex align-items-center"
                  onClick={() => handleClickChild(child)}
                  aria-hidden="true"
                  role="button"
                >
                  <div className="child-menu-item-icon">
                    <Icon.Minus width={24} height={24} strokeWidth={2.5} color="#0065ff" />
                  </div>
                  <span>{child.name}</span>
                </div>
              </div>
            );
          }
        })}
    </li>
  );
}

memo(ItemSidebarHasSub);

ItemSidebarHasSub.propTypes = {
  item: PropTypes.object,
};

import React, { useEffect } from 'react';
import PropsType from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../../store/actions/auth';
import LeftSide from './LeftSide';
import GlobalLoading from '../../GlobalLoading';
import axiosService from '../../../lib/axiosService';

function PublicLayout(props) {
  const { children } = props;
  useEffect(() => {
    const pathName = children.props.location.pathname;
    if (pathName.indexOf('/password-reset') >= 0) {
      localStorage.removeItem(process.env.TOKEN);
    }
  }, []);

  return (
    <div className="frame-view frame-onboarding bg-onboarding">
      <LeftSide />
      <div className="right-side">{children}</div>
      <GlobalLoading />
    </div>
  );
}

PublicLayout.propTypes = {
  children: PropsType.object,
};

export default PublicLayout;

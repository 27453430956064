/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import * as chatActions from '@Store/actions/chat';
import { markReadNotification } from '@Apis/chat';

const customStyles = {
  content: {
    top: '60px',
    right: '0',
    bottom: '0',
    left: 'auto',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0',
    width: '400px',
    boxShadow: '0 -9px 26px 0 rgba(0, 0, 0, 0.11)',
  },
};

function Notification() {
  const dispatch = useDispatch();
  const history = useHistory();
  const notifications = useSelector(state => state.chat.notifications);
  const [isOpenHModal, setIsOpenModal] = useState(false);
  const hotels = useSelector(state => state.auth.hotels);
  const channel = useSelector(state => state.chat.channel);
  const hotel = useSelector(state => state.chat.hotel);
  const conversationsShow = useSelector(state => state.chat.conversationsShow);
  const isConversationLoading = useSelector(state => state.chat.isConversationLoading);
  const [updateNotification, setUpdateNotification] = useState(false);
  const [notiMarkRead, setNotiMarkRead] = useState(null);

  useEffect(() => {
    dispatch(chatActions.fetchNotifications());
  }, []);

  useEffect(() => {
    if (
      updateNotification &&
      notiMarkRead &&
      !isConversationLoading &&
      hotel &&
      conversationsShow.length > 0
    ) {
      dispatch(chatActions.markReadNotificationSameHotelSameChannel({ notiMarkRead }));
      setUpdateNotification(false);
      setNotiMarkRead(null);
    }
  }, [updateNotification, notiMarkRead, isConversationLoading, hotel, conversationsShow]);

  const getHotelNameByHotelId = hotelId => {
    const hotel = hotels.find(hotel => hotel.id === hotelId);
    return hotel ? hotel.name : '';
  };

  const clearAgentNofication = notificationId => {
    const handleNotification = async () => {
      const resp = await markReadNotification(notificationId);
      const { data, status } = resp;
      if (status === 200) {
        const notiMarkRead = data.data;
        if (hotel.id === notiMarkRead.hotel_id && channel === notiMarkRead.channel) {
          dispatch(chatActions.markReadNotificationSameHotelSameChannel({ notiMarkRead }));
        }
        if (hotel.id === notiMarkRead.hotel_id && channel !== notiMarkRead.channel) {
          dispatch(chatActions.setCurrentChannel(notiMarkRead.channel));
          setNotiMarkRead(notiMarkRead);
          setUpdateNotification(true);
        }
        if (hotel.id !== notiMarkRead.hotel_id) {
          const findHotel = hotels.find(item => item.id === notiMarkRead.hotel_id);
          if (findHotel) {
            history.push(`/${findHotel.slug}/chat`);
          }
          dispatch(chatActions.setCurrentChannel(notiMarkRead.channel));
          setNotiMarkRead(notiMarkRead);
          setUpdateNotification(true);
        }
      }
    };
    handleNotification();
    setIsOpenModal(false);
  };

  const renderNotifications = () => {
    let xhtml = null;
    if (notifications && notifications.length > 0) {
      xhtml = (
        <div className="notification-list pd-x-5">
          {notifications.map(noti => {
            return (
              <button
                type="button"
                className="pd-y-10-f pd-x-10-f link-button d-flex w-100 align-items-center justify-content-between"
                key={noti.id}
                onClick={() => clearAgentNofication(noti.id)}
              >
                <div className="d-flex align-items-center">
                  <div className="avatar avatar-sm avatar-online">
                    <span className="avatar-initial rounded-circle">b</span>
                  </div>
                  <div className="pd-l-10 text-left">
                    <p className="mg-b-0 tx-medium">{noti.customer_name}</p>
                    <p className="text-uppercase mg-b-0 tx-12">
                      {getHotelNameByHotelId(noti.hotel_id)}
                    </p>
                  </div>
                </div>
                {noti.channel === 'facebook' && <i className="iconinna-messenger" />}
                {noti.channel === 'line' && <i className="iconinna-line" />}
              </button>
            );
          })}
        </div>
      );
    }
    return xhtml;
  };

  return (
    <div className="agent-notification">
      <button
        type="button"
        className="link-button pos-relative"
        onClick={() => setIsOpenModal(true)}
      >
        <Icon.Bell color="#0168fa" fill="#0168fa" />
        {notifications && notifications.length > 0 && (
          <span className="unread">{notifications.length}</span>
        )}
      </button>
      <Modal
        isOpen={isOpenHModal}
        style={customStyles}
        onRequestClose={() => setIsOpenModal(false)}
        overlayClassName=""
      >
        <div className="modal-header d-flex align-items-center justify-content-between border-0">
          <h5 className="tx-18">New Messenger</h5>
        </div>
        <div className="modal-body pd-0-f">{renderNotifications()}</div>
      </Modal>
    </div>
  );
}

export default Notification;

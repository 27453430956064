export const FETCH_HOTELS_MM = 'FETCH_HOTELS_MM';
export const FETCH_HOTELS_MM_SUCCESS = 'FETCH_HOTELS_MM_SUCCESS';

export const FETCH_CONVERSATIONS = 'FETCH_CONVERSATIONS_MM';
export const FETCH_CONVERSATIONS_SUCCESS = 'FETCH_CONVERSATIONS_MM_SUCCESS';

export const CLEAR_CONVERSATIONS = 'CLEAR_CONVERSATIONS_MM';

export const SET_MEMBER_ACTIVE = 'SET_MEMBER_ACTIVE';

export const FETCH_MESSENGES = 'FETCH_MESSENGES';
export const FETCH_MESSENGES_SUCCESS = 'FETCH_MESSENGES_SUCCESS';

export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';

export const MESSENGE_SOCKET = 'MESSENGE_SOCKET';
export const MESSENGE_SOCKET_SUCCESS = 'MESSENGE_SOCKET_SUCCESS';

export const SET_CONVERSATION_STATUS = 'SET_CONVERSATION_STATUS';

export const SET_HOTEL_MM_STATUS = 'SET_HOTEL_MM_STATUS';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';

export const TAKE_OVER_SOCKET = 'TAKE_OVER_SOCKET';
export const TAKE_OVER_SOCKET_SUCCESS = 'TAKE_OVER_SOCKET_SUCCESS';

export const FILTER_CONVERSTATIONS = 'FILTER_CONVERSTATIONS';
export const FILTER_CONVERSTATIONS_SUCCESS = 'FILTER_CONVERSTATIONS_SUCCESS';

export const MESSENGE_SOCKET_NOT_EXIT_CONVERSTATION = 'MESSENGE_SOCKET_NOT_EXIT_CONVERSTATION';

export const MESSENGE_SOCKET_NOT_EXIT_CONVERSTATION_SUCCESS =
  'MESSENGE_SOCKET_NOT_EXIT_CONVERSTATION_SUCCESS';

export const MARK_READ_NOTIFICATION_SAME_HOTEL_SAME_CHANNEL =
  'MARK_READ_NOTIFICATION_SAME_HOTEL_SAME_CHANNEL';

export const AGENT_NOTIFICATIONS_SOCKET = 'AGENT_NOTIFICATIONS_SOCKET';
export const AGENT_NOTIFICATIONS_SOCKET_SUCCESS = 'AGENT_NOTIFICATIONS_SOCKET_SUCCESS';

export const MARK_READ_NOTIFICATIONS = 'MARK_READ_NOTIFICATIONS';
export const MARK_READ_NOTIFICATIONS_SUCCESS = 'MARK_READ_NOTIFICATIONS_SUCCESS';

export const ADD_NEW_MESS = 'ADD_NEW_MESS';
export const ADD_NEW_MESS_FAILED = 'ADD_NEW_MESS_FAILED';

export const SET_COPY_FAQ = 'SET_COPY_FAQ';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';

export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE';

export const CHANGE_LANGUAGE_TO_SEND = 'CHANGE_LANGUAGE_TO_SEND';

export const CUSTOMER_NOT_EXIT_CONVERSTATION = 'CUSTOMER_NOT_EXIT_CONVERSTATION';

export const SET_GUEST_ID = 'SET_GUEST_ID';

export const SET_IGNORE_CONVERSATION_ID = 'SET_IGNORE_CONVERSATION_ID';

import axios from 'axios';
import axiosService from '../lib/axiosService';

const url = process.env.CHAT_V2;
const api = process.env.API_ENDPOINT;

export const fetchHotelsMM = query => {
  const { ids } = query;
  return axios.get(`${url}/api/hotels?filter[id]=${ids ? ids.toString() : ''}&per_page=1000`);
};

export const fetchConversation = query => {
  const { hotelId, page = 1, channel, ignoreId = '', customerChannelIds = '' } = query;
  return axios.get(`${url}/api/hotels/${hotelId}/conversations`, {
    params: {
      'filter[channel]': channel,
      'filter[ignore_id]': ignoreId ? ignoreId.toString() : '',
      page: page,
      'filter[user.channel_id]': customerChannelIds ? customerChannelIds.toString() : '',
    },
  });
};

export const fetchCustomers = query => {
  return axiosService.post(`${api}/v2/livechat/getCustomersDetails`, query);
};

export const fetchMessages = query => {
  const { conversationId, page } = query;
  return axios.get(`${url}/api/conversations/${conversationId}/messages?page=${page}`);
};

export const clearUnreadConversation = converstationId => {
  return axios.post(`${url}/api/conversations/${converstationId}/clear-unread`);
};

export const fetchNotifications = () => {
  return axiosService.get(`${api}/v2/agent-notifications`);
};

export const takeOver = query => {
  return axiosService.post(`${api}/v2/livechat/takeOver`, query);
};

export const resumeBot = query => {
  return axiosService.post(`${api}/v2/livechat/resumeBot`, query);
};

export const editFaq = (formData, faqId) => {
  const url = `${api}/v2/livechat/faqs/${faqId}`;
  return axiosService.post(url, formData);
};

export const createFaq = formData => {
  const url = `${api}/v2/livechat/faqs`;
  return axiosService.post(url, formData);
};

export const deleteFaq = faqId => {
  const url = `${api}/v2/livechat/faqs/${faqId}`;
  return axiosService.delete(url);
};

export const searchFAQs = (hotelId, search) => {
  const url = `${api}/v2/livechat/faqs`;
  const query = {
    hotel_id: hotelId,
    search,
  };
  return axiosService.get(url, query);
};

export const googleTranslate = params => {
  const url = `${process.env.GOOGLE_API_URL}?key=${process.env.GOOGLE_API_KEY}`;
  return axios.post(url, params);
};

export const getConversationDetail = id => {
  return axios.get(`${url}/api/conversations/${id}`);
};

export const markReadNotification = notificationId => {
  return axiosService.put(`${api}/v2/agent-notifications/${notificationId}/read`);
};

export const getCategories = () => {
  return axiosService.get(`${api}/v2/livechat/faq-categories`);
};

export const getFAQsByCategoryId = (categoryId, hotelId) => {
  const url = `${api}/v2/livechat/faqs`;
  const query = {
    hotel_id: hotelId,
    live_chat_faq_category_id: categoryId,
  };
  return axiosService.get(url, query);
};

export const generateTextOffer = query => {
  return axiosService.post(`${api}/v2/searchRoom/generateText`, query);
};

export const sendOfferRoomsToChat = query => {
  return axiosService.post(`${api}/v2/livechat/sendOffers`, query);
};

export const searchOfferRoom = query => {
  return axiosService.post(`${api}/v2/searchRoom`, query);
};

export const sendVoucherButton = query => {
  return axiosService.post(`${api}/v2/livechat/sendVoucherButton`, query);
};

export const fetchUnpaidPayables = query => {
  return axiosService.get(`${api}/v2/livechat/unpaidPayables`, query);
};

export const askForPayment = query => {
  return axiosService.post(`${api}/v2/livechat/askForPayment`, query);
};

export const createBooking = query => {
  return axiosService.post(`${api}/v2/createBooking`, query);
};

export const getCustomer = query => {
  return axiosService.get(`${api}/v2/livechat/getCustomer`, query);
};

export const getTags = () => {
  return axiosService.get(`${api}/v2/livechat/tags`).then(res => res.data);
};

export const createTag = query => {
  return axiosService.post(`${api}/v2/livechat/tags`, query);
};

export const attachTag = query => {
  return axiosService.post(`${api}/v2/livechat/tags/attach`, query);
};

export const detachTag = query => {
  return axiosService.post(`${api}/v2/livechat/tags/detach`, query);
};

import { fork, all } from 'redux-saga/effects';
import authSaga from './auth';
import chatSaga from './chat';
import labelsSaga from './labels';
import chatv2Saga from './chatv2';
import instayOrdersSaga from './instayOrder';
// import webviewSaga from './webview';

function* rootSaga() {
  yield all([
    yield fork(authSaga),
    yield fork(chatSaga),
    yield fork(labelsSaga),
    yield fork(chatv2Saga),
    yield fork(instayOrdersSaga),
    // yield fork(webviewSaga),
  ]);
}

export default rootSaga;

import * as types from '../actionTypes/webview';

const initialState = {
  lang: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_LANGUAGE_WEBVIEW: {
      const { lang } = action.payload;
      return {
        ...state,
        lang,
      };
    }
    default:
      return state;
  }
};

export default reducer;

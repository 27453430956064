import * as types from '../actionTypes/instayOrder';

const initialState = {
  orders: [],
  ordersStatus: null,
  newOrderCount: 0,
  meta: null,
  orderDetail: null,
  isLoadingOrderDetail: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ORDERS_SUCCESS: {
      const { data } = action.payload;
      return { ...state, orders: data.data, meta: data.meta };
    }
    case types.FETCH_ORDERS_STATUS_SUCCESS: {
      const { data } = action.payload;

      const count = data.reduce((total, current) => total + current.unread, 0);
      return {
        ...state,
        ordersStatus: data,
        newOrderCount: count,
      };
    }
    case types.UPDATE_ORDER: {
      const { data } = action.payload;
      const { dataSocket, status } = data;
      const { orders } = state;
      const findIndex = orders.findIndex(item => item.id === dataSocket.id);
      const ordersUpdated = [...orders];
      if (findIndex < 0) {
        if (status === null || status === dataSocket.status) {
          ordersUpdated.unshift(dataSocket);
        }
      } else {
        // const rejectOrder = ordersUpdated;
        ordersUpdated.splice(findIndex, 1);
      }
      return {
        ...state,
        orders: [...ordersUpdated],
      };
    }
    case types.FETCH_ORDER_DETAIL:
      return {
        ...state,
        isLoadingOrderDetail: true,
      };
    case types.FETCH_ORDER_DETAIL_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isLoadingOrderDetail: false,
        orderDetail: { ...data },
      };
    }
    case types.FETCH_ORDER_DETAIL_FAILED: {
      return {
        ...state,
        isLoadingOrderDetail: false,
      };
    }
    case types.CLEAR_ORDER_DETAIL: {
      return {
        ...state,
        orderDetail: null,
      };
    }
    case types.MARK_READ_ORDERS: {
      const { data } = action.payload;
      const { status } = data;
      const { ordersStatus, newOrderCount, orders } = state;
      let newOrderCountUpdate = 0;
      const ordersStatusUpdated = ordersStatus.map(item => {
        if (item.status === status) {
          newOrderCountUpdate = newOrderCount - item.unread;
          item.unread = 0;
          return item;
        }
        return item;
      });
      const ordersUpdate = orders.map(item => {
        if (item.status === status) {
          item.unread = 0;
          return item;
        }
        return item;
      });
      return {
        ...state,
        newOrderCount: newOrderCountUpdate,
        ordersStatus: [...ordersStatusUpdated],
        ordes: [...ordersUpdate],
      };
    }
    default:
      return state;
  }
};

export default reducer;

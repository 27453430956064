import * as types from '../actionTypes/chatv2';

export const fetchConversation = query => ({
  type: types.FETCH_CONVERSATION,
  payload: {
    query,
  },
});

export const fetchConversationSuccess = data => ({
  type: types.FETCH_CONVERSATION_SUCCESS,
  payload: {
    data,
  },
});

export const fetchConversationFailed = error => ({
  type: types.FETCH_CONVERSATION_FAILED,
  payload: {
    error,
  },
});

export const setMemberActive = data => ({
  type: types.SET_MEMBER_ACTIVE_V2,
  payload: {
    data,
  },
});

export const fetchMessenge = (conversationId, page) => ({
  type: types.FETCH_MESSENGE_V2,
  payload: {
    conversationId,
    page,
  },
});

export const fetchMessengeSuccess = data => ({
  type: types.FETCH_MESSENGE_SUCCESS_V2,
  payload: {
    data,
  },
});

export const fetchMessengeFailed = error => ({
  type: types.FETCH_MESSENGE_FAILED_V2,
  payload: {
    error,
  },
});

export const addNewMess = data => ({
  type: types.ADD_NEW_MESS,
  payload: {
    data,
  },
});

export const addNewMessFailed = id => ({
  type: types.ADD_NEW_MESS_FAILED,
  payload: {
    id,
  },
});

export const fetchHotelsFromMM = query => ({
  type: types.FETCH_HOTELS_FROM_MM,
  payload: {
    query,
  },
});

export const fetchHotelsFromMMSuccess = data => ({
  type: types.FETCH_HOTELS_FROM_MM_SUCCESS,
  payload: {
    data,
  },
});

export const fetchHotelsFromMMFailed = error => ({
  type: types.FETCH_HOTELS_FROM_MM_FAILED,
  payload: {
    error,
  },
});

export const setHotelMMActive = data => ({
  type: types.SET_HOTEL_MM_ACTIVE,
  payload: {
    data,
  },
});

export const changeChannel = data => ({
  type: types.CHANGE_CHANNEL,
  payload: {
    data,
  },
});

export const updateConversationStatus = data => ({
  type: types.UPDATE_CONVERSATION_STATUS,
  payload: {
    data,
  },
});

export const updateHotelMMStatus = data => ({
  type: types.UPDATE_HOTEL_MM_STATUS,
  payload: {
    data,
  },
});

export const messengeSocket = data => ({
  type: types.MESSENGE_SOCKET_V2,
  payload: {
    data,
  },
});

export const messengeSocketSuccess = data => ({
  type: types.MESSENGE_SOCKET_V2_SUCCESS,
  payload: {
    data,
  },
});

export const fetchNotification = query => ({
  type: types.FETCH_NOTIFICATION,
  payload: {
    query,
  },
});

export const fetchNotificationSuccess = data => ({
  type: types.FETCH_NOTIFICATION_SUCCESS,
  payload: {
    data,
  },
});

export const fetchNotificationFailed = error => ({
  type: types.FETCH_NOTIFICATION_FAILED,
  payload: {
    error,
  },
});

export const markReadNotification = query => ({
  type: types.MARK_READ_NOTIFICATION,
  payload: {
    query,
  },
});

export const markReadNotificationSameHotelSameChannel = data => ({
  type: types.MARK_READ_NOTIFICATION_SAME_HOTEL_SAME_CHANNEL,
  payload: {
    data,
  },
});

export const markReadNotificationSameHotelDiffChannel = data => ({
  type: types.MARK_READ_NOTIFICATION_SAME_HOTEL_DIFF_CHANNEL,
  payload: {
    data,
  },
});

export const markReadNotificationSuccess = data => ({
  type: types.MARK_READ_NOTIFICATION_SUCCESS,
  payload: {
    data,
  },
});

export const markReadNotificationFailed = error => ({
  type: types.MARK_READ_NOTIFICATION_FAILED,
  payload: {
    error,
  },
});

export const agentNotificationSocket = data => ({
  type: types.AGENT_NOTIFICATIONS_SOCKET,
  payload: {
    data,
  },
});

export const agentNotificationSocketSuccess = data => ({
  type: types.AGENT_NOTIFICATIONS_SOCKET_SUCCESS,
  payload: {
    data,
  },
});

export const messengeSocketNotExitConverstation = query => ({
  type: types.MESSENGE_SOCKET_V2_NOT_EXIT_CONVERSTATION,
  payload: {
    query,
  },
});

export const messengeSocketNotExitConverstationSuccess = data => ({
  type: types.MESSENGE_SOCKET_V2_NOT_EXIT_CONVERSTATION_SUCCESS,
  payload: {
    data,
  },
});

export const takeOverSocket = data => ({
  type: types.TAKE_OVER_SOCKET,
  payload: {
    data,
  },
});

export const takeOverSocketSuccess = data => ({
  type: types.TAKE_OVER_SOCKET_SUCCESS,
  payload: {
    data,
  },
});

export const filterConversation = query => ({
  type: types.FILTER_CONVERSTATION,
  payload: {
    query,
  },
});

export const filterConversationSuccess = data => ({
  type: types.FILTER_CONVERSTATION_SUCCESS,
  payload: {
    data,
  },
});

export const filterConversationFailed = error => ({
  type: types.FILTER_CONVERSTATION_FAILED,
  payload: {
    error,
  },
});

export const clearConversations = () => ({
  type: types.CLEAR_CONVERSATIONS,
});

export const changeLanguage = lang => ({
  type: types.CHANGE_LANGUAGE,
  payload: {
    lang,
  },
});

export const changeLanguageSuccess = data => ({
  type: types.CHANGE_LANGUAGE_SUCCESS,
  payload: {
    data,
  },
});

export const changeLanguageFailed = error => ({
  type: types.CHANGE_LANGUAGE_FAILED,
  payload: {
    error,
  },
});

export const changeLanguageToSend = lang => ({
  type: types.CHANGE_LANGUAGE_TO_SEND,
  payload: {
    lang,
  },
});

export const defaultLanguage = () => ({
  type: types.DEFAULT_LANGUAGE,
});

export const fetchMessgeTrans = () => ({
  type: types.FETCH_MESSENGE_TRANS,
});

export const fetchMessgeTransSuccess = data => ({
  type: types.FETCH_MESSENGE_TRANS_SUCCESS,
  payload: {
    data,
  },
});

export const fetchMessgeTransFailed = error => ({
  type: types.FETCH_MESSENGE_TRANS_FAILED,
  payload: {
    error,
  },
});

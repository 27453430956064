import * as types from '../actionTypes/chat';

const initialState = {
  hotels: [],
  channel: 'facebook',
  conversations: null,
  conversationsShow: [],
  isConversationLoading: false,
  hotel: null,
  ignoreConversationId: [],
  memberActive: null,
  messenges: null,
  messengesShow: [],
  isMessLoading: false,
  scrollToItem: 0,
  notifications: [],
  translateTo: '',
  translateToSend: '',
  shouldRefreshMessages: true,
  shouldRefreshConversations: true,
  faqCopy: null,
  guestId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CONVERSATIONS: {
      return {
        ...state,
        isConversationLoading: true,
      };
    }
    case types.FETCH_HOTELS_MM: {
      return {
        ...state,
        conversationsShow: [],
        conversations: null,
        hotel: null,
      };
    }
    case types.FETCH_MESSENGES: {
      return {
        ...state,
        isMessLoading: true,
      };
    }
    case types.FETCH_HOTELS_MM_SUCCESS: {
      const { hotelsMM, hotelMM } = action.payload.data;
      return {
        ...state,
        hotels: hotelsMM,
        hotel: hotelMM,
      };
    }
    case types.CLEAR_CONVERSATIONS: {
      return {
        ...state,
        conversations: null,
        conversationsShow: [],
      };
    }
    case types.FETCH_CONVERSATIONS_SUCCESS: {
      const conversations = action.payload.data;
      const { conversationsShow } = state;
      const newConversationShow = [...conversationsShow, ...conversations.data];
      return {
        ...state,
        conversations,
        conversationsShow: newConversationShow,
        isConversationLoading: false,
      };
    }
    case types.SET_MEMBER_ACTIVE: {
      const { data } = action.payload;
      const { shouldRefreshMessages } = state;
      return {
        ...state,
        messenges: null,
        messengesShow: [],
        memberActive: data,
        shouldRefreshMessages: !shouldRefreshMessages,
        faqCopy: null,
      };
    }
    case types.FETCH_MESSENGES_SUCCESS: {
      const messenges = action.payload.data;
      const { messengesShow } = state;
      const newMessengeShow = [...messenges.data.reverse(), ...messengesShow];

      let scrollTo = 0;
      if (messenges.meta.current_page === 1) {
        scrollTo = 14;
      } else {
        scrollTo = newMessengeShow.length - 14 * (messenges.meta.current_page - 1);
      }
      return {
        ...state,
        messenges,
        messengesShow: newMessengeShow,
        isMessLoading: false,
        scrollToItem: scrollTo,
      };
    }
    case types.SET_CURRENT_CHANNEL: {
      const channel = action.payload.data;
      const { shouldRefreshConversations } = state;
      return {
        ...state,
        channel,
        memberActive: null,
        messengesShow: [],
        messenges: null,
        shouldRefreshConversations: !shouldRefreshConversations,
      };
    }
    case types.MESSENGE_SOCKET_SUCCESS: {
      const { data } = action.payload;
      const { scrollToItem } = state;
      let scrollTo = 0;
      if (scrollToItem === 14) {
        scrollTo = data.length;
      }
      return {
        ...state,
        messengesShow: data,
        scrollToItem: scrollTo,
      };
    }
    case types.SET_CONVERSATION_STATUS: {
      const { data } = action.payload;
      const { conversationsShow } = state;
      const findConversation = conversationsShow.find(item => item.id === data.id);
      let cloneConvertionsShow = conversationsShow;
      if (findConversation) {
        findConversation.has_unread_message = data.has_unread_message === 1;
        findConversation.updated_at = data.updated_at;
        if (data.has_unread_message) {
          cloneConvertionsShow = conversationsShow.filter(item => item.id !== data.id);
          cloneConvertionsShow.unshift(findConversation);
        }
      }
      return {
        ...state,
        conversationsShow: [...cloneConvertionsShow],
      };
    }
    case types.SET_HOTEL_MM_STATUS: {
      const { data } = action.payload;
      const { hotels } = state;
      const findHotelMM = hotels.find(item => item.id === data.id);
      if (findHotelMM) {
        findHotelMM.unread_conversation_count = data.unread_conversation_count;
        findHotelMM.unread_channel = data.unread_channel;
      }
      return {
        ...state,
        hotels: [...hotels],
      };
    }
    case types.FETCH_NOTIFICATIONS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        notifications: data.data,
      };
    }
    case types.TAKE_OVER_SOCKET_SUCCESS: {
      const { data } = action.payload;
      const { memberActive, conversationsShow } = data;
      return {
        ...state,
        memberActive: { ...memberActive },
        conversationsShow: [...conversationsShow],
      };
    }
    case types.MESSENGE_SOCKET_NOT_EXIT_CONVERSTATION_SUCCESS: {
      const { data } = action.payload;
      const { conversationsShow, ignoreConversationId } = state;
      return {
        ...state,
        conversationsShow: [data, ...conversationsShow],
        ignoreConversationId: [...ignoreConversationId, data.id],
      };
    }
    case types.MARK_READ_NOTIFICATIONS_SUCCESS: {
      const { memberActive, conversationsShow, ignoreConversationId } = action.payload.data;
      const { shouldRefreshMessages } = state;
      return {
        ...state,
        memberActive,
        messenges: null,
        messengesShow: [],
        ignoreConversationId,
        shouldRefreshMessages: !shouldRefreshMessages,
        conversationsShow,
      };
    }
    case types.AGENT_NOTIFICATIONS_SOCKET_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        notifications: data,
      };
    }
    case types.ADD_NEW_MESS: {
      const mess = action.payload.data;
      const { messengesShow } = state;
      return {
        ...state,
        messengesShow: [...messengesShow, mess],
        scrollToItem: messengesShow.length + 1,
      };
    }
    case types.ADD_NEW_MESS_FAILED: {
      const { id } = action.payload;
      const { messengesShow } = state;
      const cloneMessengesShow = JSON.parse(JSON.stringify(messengesShow));
      const findMess = cloneMessengesShow.find(mess => mess.id === id);
      if (findMess) {
        findMess.error = true;
      }
      return {
        ...state,
        messengesShow: [...cloneMessengesShow],
      };
    }
    case types.SET_COPY_FAQ: {
      const { data } = action.payload;
      return {
        ...state,
        faqCopy: data,
      };
    }
    case types.CHANGE_LANGUAGE_SUCCESS: {
      const { translations, lang } = action.payload.data;
      return {
        ...state,
        translateTo: lang,
        messengesShow: translations,
      };
    }
    case types.SET_DEFAULT_LANGUAGE: {
      const { shouldRefreshMessages } = state;
      return {
        ...state,
        translateTo: '',
        shouldRefreshMessages: !shouldRefreshMessages,
        messenges: null,
        messengesShow: [],
      };
    }
    case types.CHANGE_LANGUAGE_TO_SEND: {
      const { lang } = action.payload;
      return {
        ...state,
        translateToSend: lang,
      };
    }
    case types.SET_GUEST_ID: {
      const { customerChannelId } = action.payload;
      return {
        ...state,
        guestId: customerChannelId,
      };
    }
    case types.SET_IGNORE_CONVERSATION_ID: {
      const { ignoreId } = action.payload;
      const { ignoreConversationId } = state;
      return {
        ...state,
        ignoreConversationId: [...ignoreConversationId, ignoreId],
      };
    }
    default:
      return state;
  }
};

export default reducer;

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const GET_INFO = 'GET_INFO';
export const GET_INFO_SUCCESS = 'GET_INFO_SUCCESS';
export const GET_INFO_FAILED = 'GET_INFO_FAILED';

export const SET_HOTEL_ACTIVE = 'SET_HOTEL_ACTIVE';
export const SET_HOTEL_ACTIVE_SUCCESS = 'SET_HOTEL_ACTIVE_SUCCESS';

export const NOTIFY_HOTEL = 'NOTIFY_HOTEL';
export const NOTIFY_HOTEL_SUCCESS = 'NOTIFY_HOTEL_SUCCESS';

export const UPDATE_ONBOARD_HOTEL = 'UPDATE_ONBOARD_HOTEL';
export const UPDATE_ONBOARD_HOTEL_SUCCESS = 'UPDATE_ONBOARD_HOTEL_SUCCESS';

export const UPDATE_CURRENCY_SETTING = 'UPDATE_CURRENCY_SETTING';
export const UPDATE_CURRENCY_SETTING_SUCCESS = 'UPDATE_CURRENCY_SETTING_SUCCESS';

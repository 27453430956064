import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import HotelRoomsLayout from '@ComponentsV2/Layout/HotelRoomsLayout';
import axiosService from '@Lib/axiosService';
import { useSelector, useDispatch } from 'react-redux';

function DashboardRoutes(props) {
  const { component: YourComponent, ...remainProps } = props;
  const { type } = remainProps;
  const hotel = useSelector(state => state.auth.hotel);

  const token = localStorage.getItem(process.env.TOKEN);
  if (token) {
    axiosService.setHeader('Authorization', `Bearer ${token}`);
  }
  return (
    <Route
      {...remainProps}
      render={routeProps => {
        if (token && hotel) {
          return (
            token && (
              <HotelRoomsLayout type={type}>
                <YourComponent {...routeProps} />
              </HotelRoomsLayout>
            )
          );
        }
        return '';
      }}
    />
  );
}

DashboardRoutes.propTypes = {
  component: PropTypes.object,
  type: PropTypes.string,
};

export default DashboardRoutes;

import * as types from '../actionTypes/instayOrder';

export const fetchOrders = query => ({
  type: types.FETCH_ORDERS,
  payload: {
    query,
  },
});

export const fetchOrdersSuccess = data => ({
  type: types.FETCH_ORDERS_SUCCESS,
  payload: {
    data,
  },
});

export const fetchOrdersFailed = error => ({
  type: types.FETCH_ORDERS_FAILED,
  payload: {
    error,
  },
});

export const fetchOrdersStatus = query => ({
  type: types.FETCH_ORDERS_STATUS,
  payload: {
    query,
  },
});

export const fetchOrdersStatusSuccess = data => ({
  type: types.FETCH_ORDERS_STATUS_SUCCESS,
  payload: {
    data,
  },
});

export const fetchOrdersStatusFailed = error => ({
  type: types.FETCH_ORDERS_STATUS_FAILED,
  payload: {
    error,
  },
});

export const updateOrder = data => ({
  type: types.UPDATE_ORDER,
  payload: {
    data,
  },
});

export const fetchOrderDetail = query => ({
  type: types.FETCH_ORDER_DETAIL,
  payload: {
    query,
  },
});

export const fetchOrderDetailSuccess = data => ({
  type: types.FETCH_ORDER_DETAIL_SUCCESS,
  payload: {
    data,
  },
});

export const fetchOrderDetailFailed = error => ({
  type: types.FETCH_ORDER_DETAIL_FAILED,
  payload: {
    error,
  },
});

export const clearOrderDetail = () => ({
  type: types.CLEAR_ORDER_DETAIL,
});

export const markReadOrders = data => ({
  type: types.MARK_READ_ORDERS,
  payload: {
    data,
  },
});

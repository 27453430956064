import React from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-feather';
import DropDownHotels from './DropDownHotels';
import Notification from './Notification';
import UserControl from './UserControl';
import NavMenu from './NavMenu';

export default function Header({ type }) {
  return (
    <header className="navbar navbar-header navbar-header-fixed">
      <button
        type="button"
        id="sidebarMenuOpen"
        className="burger-menu link-button"
        onClick={() => {}}
      >
        <Icon.Menu />
      </button>
      <DropDownHotels type={type} />
      <NavMenu type={type} />
      <div className="navbar-right tx-medium">
        <Notification />
        <UserControl />
      </div>
    </header>
  );
}

Header.propTypes = {
  type: PropTypes.string,
};

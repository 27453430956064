import { toast } from 'react-toastify';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export function toastSuccess(message) {
  if (message) {
    toast.success(message);
  }
}

export function toastError(error) {
  let message = null;
  if (typeof error === 'string') {
    message = error;
  } else {
    const { status, data, statusText } = error;
    if (data && data.message) message = data.message;
    else message = statusText;
  }
  toast.error(
    <div>
      {message} <br />
    </div>,
  );
}

export function toastFailed(error) {
  const { response } = error;
  const { errors } = response.data;
  if (errors) {
    const mess = Object.values(errors);
    const errorString = mess.reduce((r, v) => r.concat(`${v}<br />`), '');
    toast.error(<div>{ReactHtmlParser(errorString)}</div>);
  } else {
    const { status } = response;
    toast.error(
      <div>
        {status} <br />
      </div>,
    );
  }
}

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Icon from 'react-feather';

function PageNotFound() {
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const timer1 = setTimeout(() => setShowLoading(true), 500);
    return () => {
      clearTimeout(timer1);
    };
  }, [showLoading]);
  return (
    <div className="content content-auth-alt">
      {showLoading && (
        <div className="container ht-100p tx-center">
          <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
            <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">404 Page Not Found</h1>
            <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">
              Oopps. The page you were looking for doesn&apos;t exist.
            </h5>
            <p className="tx-color-03 mg-b-30">
              You may have mistyped the address or the page may have moved. Try searching below.
            </p>
            <div className="tx-14 mg-t-5 tx-center">
              <button
                type="button"
                className="link-button color-blue"
                onClick={() => history.goBack()}
              >
                <img src="/images/arrow-left.png" alt="arrow-back" />
                <span className="mg-l-10">Back to Dashboard</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PageNotFound;
